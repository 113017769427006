import React, { Fragment } from 'react';
import { usePermissions, CreateButton, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { ValidateField, FormattedDateField, LockField, AvatarField } from '../../control/field/index'
import { CardActions } from '@material-ui/core';
import { hasWritePermissions } from '../../util/function';

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" content="Etes-vous sur de vouloir supprimer ces messages? Les utilisateurs ne les verront plus dans leur boîte de réception" {...props} />
    </Fragment>
);


const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const ListActions = ({ basePath, data, resource }) => {
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return null;
    return <CardActions style={cardActionStyle}>
        <CreateButton basePath={basePath} />
    </CardActions>
};

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.notice.list')}</span>;
};

const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};

const statusList = [
    { id: 0, name: 'Pas encore publié' },
    { id: 1, name: 'Publié' }
];

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Titre" source="title" alwaysOn resettable />
        <TextInput label="Contenu" source="content" alwaysOn resettable />
        <SelectInput label="État" source="published" alwaysOn choices={statusList}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1' />

    </Filter>
);
const rowClick = (id, basePath, record) => parseInt(record.published) === 1 ? 'show' : 'edit';
const ListNotice = ({ permissions, ...props }) => (
    <List title={<Title />} {...props} sort={{ field: 'create_time', order: 'DESC' }}
        filters={<ListFilter permissions={permissions} actions={<ListActions />} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick={rowClick}>
            <TextField source="title" label="Titre" />
            <ContentTextField source="content" label="Contenu" />
            <FormattedDateField source="create_time" label="Date de création" locales="fr-FR" format="format_date_time" />
            {/* <LockField source="locked" label="Vérouillage"/> */}
            <ValidateField source="published" label='Publié?' />
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListNotice;

