import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Typography from '@material-ui/core/Typography';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
// import UserIcon from '@material-ui/icons/AccountCircle';
// import LockIcon from '@material-ui/icons/Lock';
// import SettingsIcon from '@material-ui/icons/Settings';


const role = localStorage.getItem('role');

class NewUserMenuView extends Component {
   
    componentDidMount() {
        console.log("Role: " + role);
        this.fetchProfile();

        if(role === 'Admin')
            this.fetchConfig();
    }

    fetchProfile = () => {
        console.log("Load profile from server for user ");
        // this.props.crudGetOne(
        //     // The resource
        //     'profile',
        //     // The id of the resource item to fetch
        //     'noid',
        //     // The base path. Mainly used on failure to fetch the data
        //     '/view-my-profile',
        //     // Whether to refresh the current view. I don't need it here
        //     false
        // );
    };

    fetchConfig = () => {
        console.log("Load config from server ");
        // this.props.crudGetOne(
        //     // The resource
        //     'config',
        //     // The id of the resource item to fetch
        //     'noid',
        //     // The base path. Mainly used on failure to fetch the data
        //     '/doc',
        //     // Whether to refresh the current view. I don't need it here
        //     false
        // );
    };

    render() {
        const { crudGetOne, profile, ...props } = this.props;
        const role = localStorage.getItem('role');
        return (
            // <UserMenu label={typeof profile !== 'undefined' ? (typeof profile.email !== 'undefined' ? profile.email : '') :''} {...props}>
            <UserMenu {...this.props}>
                {/* <MenuItemLink
                    to="/view-my-profile"
                    primaryText="Mon profil"
                    leftIcon={<UserIcon />}
                />
                 <MenuItemLink
                    to="/change-password"
                    primaryText="Changer le mot de passe"
                    leftIcon={<LockIcon />}
                />
                {role === 'Admin' && <MenuItemLink
                    to="/config"
                    primaryText="Règles du système"
                    leftIcon={<SettingsIcon />}
                />} */}
                
                
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    console.log('State admin resources:');
    console.log(state.admin.resources);
    
    const id = 'noid';
    const profileState = state.admin.resources['profile'];
    const configState = state.admin.resources['config'];
    return {
        profile: profileState ? profileState.data[id] : null,
        config: configState ? configState.data[id] : null
    };
};

const NewUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(NewUserMenuView);
export default NewUserMenu;

// const mapStateToProps = state => {
//     console.log("Stored user id: " + userId);
//     const resource = 'profile';
//     // const id = 'my-profile';
//     const profileState = state.admin.resources[resource];

//     console.log("Profile state: " + JSON.stringify(profileState));
//     var profile = profileState ? profileState.data[userId] : null;
//     if(typeof profile !== 'undefined')
//         profile.id = userId;
//     console.log("Profile " + JSON.stringify(profile));
//     // if(typeof profile == 'undefined')
//     //     profile = {};
//     return {
//         profile
//     };
// };

// const NewUserMenu = connect(
//     mapStateToProps,
//     { crudGetOne }
// )(NewUserMenuView);

// const NewUserMenu = connect(
//     undefined,
//     { crudGetOne }
// )(NewUserMenuView);

// export default NewUserMenu;