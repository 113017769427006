import React, { Fragment } from 'react';
import { CardActions, Pagination, ReferenceField, ReferenceInput, AutocompleteInput, CreateButton, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';

import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { ValidateField, FormattedDateField, DataStatusField } from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);

const ListActions = ({ basePath }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    { id: 0, name: 'Non-vérrouillé' },
    { id: 1, name: 'Vérrouillé' }
];

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Plaque" source="plate_number" alwaysOn resettable />
        <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_brand" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        <ReferenceInput label="Modèle" source="model_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_model" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput>
        {/* 
        <ReferenceInput label="Chauffeur" source="driver_id" reference="driver" perPage={99999} emptyText="Tous" resettable alwaysOn>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput> */}

        {/* <SelectInput label="État" source="status" choices={statusList} alwaysOn
            allowEmpty emptyText="Tous les états" emptyValue='-1' initialValue='-1'
        /> */}
        {/* <SelectInput label="Vérrouillage" source="locked" choices={lockeds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
        {/* <SelectInput label="Validation" source="validated" choices={validateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

// const DriverActions = ({
//     bulkActions,
//     basePath,
//     currentSort,
//     displayedFilters,
//     exporter,
//     filters,
//     filterValues,
//     onUnselectItems,
//     resource,
//     selectedIds,
//     showFilter,
//     total
// }) => (
//     <CardActions>
//         {bulkActions && React.cloneElement(bulkActions, {
//             basePath,
//             filterValues,
//             resource,
//             selectedIds,
//             onUnselectItems,
//         })}
//         {filters && React.cloneElement(filters, {
//             resource,
//             showFilter,
//             displayedFilters,
//             filterValues,
//             context: 'button',
//         })}
//         <ExportFileButton filterValues={filterValues} resource={resource} basePath={basePath} />
//     </CardActions>
// );

const ListVehicle = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <List pagination={<ListPagination />} title={<Title />} {...props} perPage={50}
            filters={<ListFilter permissions={permissions} />}
            // actions={<DriverActions/>}
            bulkActionButtons={<BulkActionButtons />} exporter={false} resource="vehicle">
            <Datagrid rowClick="show">
                <TextField source="plate_number" label="Plaque" />
                {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" 
                        source="model_id" reference="vehicle_model"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField> */}

                <TextField source="brand_name" label="Marque" />
                <TextField source="model_name" label="Modèle" />
                {/* <TextField source="vehicle_type_name" label="Type"/> */}
                {/* <TextField source="color" label="Couleur"/> */}
                {/* <TextField source="color" label="Couleur"/> */}
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField> */}
                <TextField source="color" label="Couleur" />
                <TextField source="seats" label="Nombre de passagers max." />
                <TextField source="luggages" label="Nombre de bagages max." />
                <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" />
                <FormattedDateField source="control_date" label="Date du prochain contrôle technique" locales="fr-FR" />

                {/* <ValidateField source="is_default" label="Par défaut" /> */}
                {/* <DataStatusField source="status" label="" /> */}
                {/* <ValidateField source="is_locked" label="Vérrouillé" /> */}
            </Datagrid>
        </List>);
};

export default ListVehicle;

