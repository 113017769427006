import React, {Fragment} from 'react';
import { List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {ValidateField, FormattedDateField, LockField, AvatarField} from '../../control/field/index'
import StarRatings from 'react-star-ratings';

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" content="Etes-vous sur de vouloir supprimer ces messages? Les utilisateurs ne les verront plus dans leur boîte de réception" {...props} />
    </Fragment>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.rate_system.list')}</span>;
};

const ContentTextField = ({ source, record = {}, label}) => {
    if(typeof(record) !== 'undefined'){
        var content = record['content'];
        if(typeof(content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig,"");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}
    
ContentTextField.defaultProps = {
    addLabel: true,
};

const statusList = [
    {id: 0, name: 'Pas encore publié'},
    {id: 1, name: 'Publié'}
];

const RatingField  =  ({ source, record = {}, label}) => {
    // return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    return <StarRatings
    rating={Number(record.rating)}
    // rating={4}
    starRatedColor="#FCC814"
    numberOfStars={5}
    starDimension="32px"
        starSpacing="5px"
    name='Notation au chauffeur'
  />
}

RatingField.defaultProps = {
    addLabel: true,
};

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/> */}
        {/* <SelectInput label="État" source="published" alwaysOn choices={statusList} 
        allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/>
         */}
    </Filter>
);

const ListNotice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} sort={{ field: 'create_time', order: 'DESC' }}
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="">
            <TextField source="email" label = "Email"/>
            {/* <TextField source="first_name" label = "Email"/>
            <TextField source="last_name" label = "Email"/> */}
            <RatingField source="rating" label = "Notation"/>
            <TextField source="comment" label = "Avis"/>
            {/* <ContentTextField source="content" label = "Contenu"/> */}
            {/* <FormattedDateField source="create_time" label="Date de création"  locales="fr-FR" format="format_date_time" /> */}
            {/* <LockField source="locked" label="Vérouillage"/> */}
            {/* <ValidateField source="published" label='Publié?'/> */}
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListNotice;

