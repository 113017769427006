
import React from 'react';
import { SimpleShowLayout, TextField, Show, Labeled, SelectField } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton } from 'react-admin';
import { rights } from '../../util/variant';
import DeleteButton from '../../control/button/delete-button';

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const ShowActions = ({ basePath, data, resource, id }) => {
    console.log("Show actions user id " + id);
    const userId = localStorage.getItem("user_id");
    console.log("Show actions my user id " + userId);
    const isMe = (id === userId) || `${id}` === `${userId}`;
    return <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data} />
        {/* {isMe && <DeleteButton basePath={basePath} record={data} />} */}
        {!isMe && <DeleteButton resource="user" basePath={basePath} record={data} content="Voulez-vous supprimer ce compte opérateur ?" />}
    </CardActions>
};

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};



const Title = ({ record }) => {
    return <span>Compte: {record ? `${record.email}` : ''}</span>;
};

const AgencyField = ({ record, ...rest }) =>
    record && record.email !== 'admin'
        ? (<Labeled label="Agence">
            <TextField source="agency.name" reference="agency" record={record} {...rest} />
        </Labeled>
        )
        : null;

// const ShowUser = ({ permissions, ...props }) => console.log(props) || (
const ShowUser = ({ classes, permissions, ...props }) => {
    console.log("Show user props " + JSON.stringify(props));
    return (
        <Show title={<Title />} {...props} actions={<ShowActions id={props.id} {...props} />}>

            <SimpleShowLayout>
                <TextField source="user_name" label="Identifiant" />
                <TextField source="email" label="Email" />
                <SelectField source="role" label="Droits" choices={rights} />

                {/* <TextField source="first_name" label="Prénom" className={classes.inlineBlock} />
            <AgencyField/> */}
                {/* <FormDataConsumer>
                 {({ formData, ...rest }) => formData.email !== 'admin' &&
                      <TextField label="Agence" source="agency.name" reference="agency" {...rest} />
                 }
             </FormDataConsumer> */}

                {/* {permissions === 'Admin' && showAgency &&
            <TextField source="agency.name" label="Agence"/>} */}
            </SimpleShowLayout>
        </Show>

    );
}

// export default withStyles(styles)(ShowUser);
export default ShowUser;