
import React from 'react';
import { TextInput, Labeled, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles } from '@material-ui/core/styles';
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

import { locationTypes } from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.edit')}: {record ? `${record.name}` : ''}</span>;
};

const EditVehicle = ({ classes, permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions />}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>

                <TextInput source="plate_number" label="Numéro de véhicule" />

                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateInput source="circulation_date" label="&nbsp;&nbsp;Date"
                        style={{ 'width': '800px' }}
                        autoComplete="" options={{ format: '  DD/MM/yyyy' }}
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                    />
                    <Labeled label="(de première mise en circulation)">
                    </Labeled>
                </MuiPickersUtilsProvider>


                <RadioButtonGroupInput source="locked" label="Verrouillé?"
                    choices={[
                        { id: '0', name: 'Non' },
                        { id: '1', name: 'Oui' },
                    ]} initialValue='0' />

            </SimpleForm>
        </Edit>
    )
};

export default EditVehicle;