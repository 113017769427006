import React, { Fragment } from 'react';
import { ReferenceInput, AutocompleteInput, DateInput, ReferenceField, ShowButton, EditButton, Filter, TextInput, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import frLocale from "date-fns/locale/fr";
import { OrderCancelButton } from '../../control/button/index';
import {
    OrderTypeField, OrderTypeFilterField, OrderStatusField, OrderRunningStatusFilterField, FormattedDateField, OrderActivityTypeField,
    FullNameClientField, FullNameDriverField
} from '../../control/field/index'
import { orderRunningStatusValues } from '../../control/field/order-status-field'
const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const Title = ({ record }) => {
    return <span>Commandes</span>;
};

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable />
        <ReferenceInput label="Email client" source="client_id" reference="client" perPage={99999} alwaysOn resettable
            emptyText="Tous"
        >
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>
        <ReferenceInput label="Email chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>
        {/* <TextInput label="Nom de Jockey" source="user_code" alwaysOn resettable/> */}
        <SelectInput label="État" source="status" choices={orderRunningStatusValues}
            optionText={<OrderRunningStatusFilterField />}
            alwaysOn />
        <TextInput label="Adresse de départ" source="from_address" resettable />
        <TextInput label="Adresse d'arrivée" source="to_address" resettable />
        {/* <DateInput label="Prise en charge demandée" source="pick_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} /> */}

        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

const ComplexName = ({ record }) => {
    if (record === null || record === 'undefined' || typeof (record) === 'undefined') return <div />;
    return <div>
        <span style={{ fontSize: 14 }}>{record['full_name']}</span>
        <br />
        <span style={{ fontSize: 14 }}>{"(" + record['email'] + ")"}</span>
    </div>;
};

// const DriverName = ({ record }) => {
//     if (record === null || record === 'undefined' || typeof (record) === 'undefined') return <div />;
//     return <div>
//         <span>{record['full_name']}</span>
//         <br />
//         <span>{"(" + record['email'] + ")"}</span>
//     </div>;
// };


const ListOrderRunning = ({ permissions, ...props }) => {
    // if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return <List title={<Title />} {...props}
        filters={<ListFilter />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        {/* <Datagrid rowClick="show"> */}
        <Datagrid rowClick="show">
            {/* <ShowButton/> */}
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée" />
            <TextField source="order_number" label="Nº de commande" />
            {/* <OrderTypeField source="order_type" label= "Type de commande"/> */}
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="show">
                    <TextField source="name" />
                </ReferenceField> */}
            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            {/* <FullNameClientField label="Client" /> */}

            <TextField source="from_address" label="Adresse de départ" />
            <TextField source="to_address" label="Adresse d'arrivée" />
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée" />
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <FullNameDriverField label="Chauffeur" /> */}
            <ReferenceField label="Client" source="client_id" reference="client" link="" perPage={999999}>
                {/* <TextField source="full_name" /> */}
                <ComplexName />
            </ReferenceField>
            <ReferenceField label="Chauffeur" source="driver_id" reference="driver_all_type" link="" perPage={999999}>
                {/* <TextField source="full_name" /> */}
                <ComplexName />
            </ReferenceField>
            <TextField source="total" label="Prix(Franc CFA)" />
            <OrderStatusField source="status" label="État" />
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure de création" />
            {/* <ShowButton/>
            <OrderCancelButton/> */}
        </Datagrid>
    </List>
};

export default ListOrderRunning;

