import React, { Fragment } from 'react';
import { List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { ValidateField } from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_type.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

var lockeds = [];
lockeds.push({ id: 0, name: 'Non-vérrouillé' });
lockeds.push({ id: 1, name: "Vérrouillé" });

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn resettable choices={locationTypes}/> */}
        <SelectInput label="État" source="locked" choices={lockeds}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1' alwaysOn
        />
    </Filter>
);

const ListVehicleType = ({ permissions, ...props }) => (
    <List title={<Title />} {...props}
        filters={<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Nom" />
            <TextField source="max_seats" label="Sièges max" />
            <TextField source="ordering" label="Ordre d'apparition" />
            {/* <TextField source="max_luggages" label = "Bagagges max"/> */}
            <ImageField source="map_icon.url" label="Icone sur la carte" />
            <ImageField source="select_icon.url" label="Icone pour la sélection" size='48' />
            <ValidateField source="locked" label='Verrouillé' />
            {/* <DateField source="birth_day" label="Date de naissance"  locales="fr-FR"/> */}
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
            <EditButton />
        </Datagrid>
    </List>
);

export default ListVehicleType;

