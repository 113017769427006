import React, { Fragment } from 'react';
import { usePermissions, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { hasAdminPermissions } from '../../util/function';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.email_template.list')}</span>;
};

const TypeTextField = ({ source, record = {}, label }) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof (locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}

TypeTextField.defaultProps = {
    addLabel: true,
};

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        {/* <TextInput label="Code" source="code" alwaysOn resettable/> */}
        <TextInput label="Name" source="name" alwaysOn resettable />

    </Filter>
);

const ListEmailTemplate = ({ ...props }) => {
    const { permissions } = usePermissions();
    const hasPermissions = hasAdminPermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;

    return <List title={<Title />} {...props}
        filters={<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Nom" />
            {/* <TextField source="c_subject" label="Objet"/> */}
            {/* <TextField source="c_body" label="Contenu"/> */}
            {/* <TextField source="c_sender" label="Email d'expéditeur"/>
            <TextField source="c_sender_name" label="Nom d'expéditeur"/> */}
            {/* <TextField source="code" label="Code"/> */}
        </Datagrid>
    </List>
};

export default ListEmailTemplate;

