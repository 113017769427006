
import React from 'react';
import { TextInput, SelectInput, PasswordInput, Create, SimpleForm } from 'react-admin';
// import AgencyReferenceInput from './control/agency-reference-input'

import { TabbedForm, FormTab } from 'react-admin'
import { withStyles } from '@material-ui/core/styles';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';
import { rights } from '../../util/variant';

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const validateEmail = email();
const validateName = [required()];
const validatePassword = (value, allValues) => {
    if (!value) {
        return 'Veuillez saisir un mot de passe';
    }

    // if (value !== allValues.pasword_again) {
    //     return 'Mots de passes ne sont pas pareils';
    // }

    if (value !== allValues.password) {
        return 'Mots de passes ne sont pas pareils';
    }
    return undefined;
}

const Title = ({ record }) => {
    return <span>Compte</span>;
};



const CreateUser = ({ classes, permissions, ...props }) => (
    <Create title={<Title />} {...props}>
        {/* <SimpleForm redirect="list" autoComplete="nope">
            <TextInput source="email" type="text" label="Identifiant(Email)" autoComplete="new-password"/>
            <TextInput source="last_name" label="Nom" formClassName={classes.inlineBlock} autoComplete="new-password"/>
            <TextInput source="first_name" label="Prénom" formClassName={classes.inlineBlock} autoComplete="new-password"/>
            {permissions === 'Admin' &&
                <AgencyReferenceInput label="Agence" source="agency" reference="agency"/>}    
            <TextInput source="password" type="password" label="Mot de passe" autoComplete="new-password"/>
            <TextInput source="password_again" type="password" label="Mot de passe confirmé" autoComplete="new-password"/>    
        </SimpleForm> */}
        {/* <TabbedForm redirect="list">
            <FormTab label="Infos"> */}
        <SimpleForm redirect="list" autoComplete="nope">

            <TextInput source="user_name" label="Identifiant"
                // formClassName={classes.inlineBlock}
                autoComplete="new-password" validate={validateName} />
            <TextInput source="email" type="email" label="Email" autoComplete="new-password"
                validate={[required(), validateEmail]} />
            <SelectInput source="role" label="Droits" choices={rights} validate={[required()]} />
            {/* <TextInput source="first_name" label="Prénom" formClassName={classes.inlineBlock}
                autoComplete="new-password" validate={validateName} />
            {permissions === 'Admin' &&
                <AgencyReferenceInput label="Agence" source="agency" reference="agency" validate={required()} />} */}
            {/* </FormTab> */}

            {/* <FormTab label="Mot de passe"> */}
            {/* <TextInput source="password" type="password" label="Mot de passe"
                autoComplete="new-password" validate={required()} />
            <TextInput source="password_again" type="password" label="Mot de passe confirmé"
                autoComplete="new-password" validate={[required(), validatePassword]} /> */}
        </SimpleForm>
        {/* </FormTab> */}


        {/* </TabbedForm> */}
    </Create>
);

// export default withStyles(styles)(CreateUser);
export default CreateUser;