import React from 'react';
import PropTypes from 'prop-types';
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import { TRUE, FALSE } from '../../util/constant';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    parent: {
        //    margin:'auto',
        //    display: 'flex',
        //    alignItems: 'center',
        //    justifyContent: 'center',
    },
});

const ValidateField = ({ source, record = {}, label }) => {
    const classes = useStyles();
    return <div className={classes.parent}>
        {parseInt(record[source]) == TRUE ? <ActivateIcon color='primary' /> : <NotActivateIcon color='disabled' />}
    </div>;

};
// <div>
//     <span class>{record[source]}</span>
//     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
//         width='20' height='20'
//         alt={''}/>
// </div>;

ValidateField.defaultProps = {
    addLabel: true,
};

export default ValidateField;