import Create from './create'
import Edit from './edit'
import List from './list'
import Show from './show'
import DriverIcon from '@material-ui/icons/Person';

export default {
    // create: Create,
    // edit: Edit, 
    list: List,
    show: Show,
    icon: DriverIcon,
}