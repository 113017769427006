import React, { View } from 'react';
import { AppBar } from 'react-admin';
import NewUserMenu from './new-user-menu';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// import Logo from '../../res/logo'

export const Profile = props =>
    <span>{localStorage.getItem('email')}</span>

    ;

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },

    background_image: 'linear-gradient(to right, #39E89B, #FECA47)',
};

// let email = localStorage.getItem('email');
const MyAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar {...props}>
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        {/* <span className={classes.spacer} /> */}
        {/* <Logo styles={{height:40}}/> */}
        <span className={classes.spacer} />
        {/* <Profile/> */}
    </AppBar>
));

const NewAppBar = props =>
    <MyAppBar {...props} userMenu={<NewUserMenu />} />;
export default NewAppBar;
