
import React, { Fragment } from 'react';
import { Tab, TabbedShowLayout, ListButton, Filter, List, Responsive, ReferenceField, ReferenceManyField, Datagrid, ReferenceInput, AutocompleteInput, EditButton, SimpleShowLayout, TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { BACKEND_FILE_URL, locationDict } from '../../util/variant'
import { Status } from '../../util/constant'
import { ValidateField, FormattedDateField, DocNameField, FileCountField, DocStatusField } from '../../control/field/index'
import { DataLockButton, BatchDeleteButton, EventEditButton, DataUnlockButton, EventCreateButton } from '../../control/button/index'
import { Form, Field } from 'react-final-form'
import { removeHTML } from '../../util/function';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import MediaViewer from '../../control/viewer/media-viewer';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', borderRadius: '50%' },


});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/* {typeof data != 'undefined' && typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource} />
        } */}
        {/* <EditButton basePath={basePath} record={data} /> */}
    </TopToolbar>
);

// const DownloadQR = ({ record }) => {
//     const canvas = document.getElementById("qrcode");
//     const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
//     console.log('pngUrl', pngUrl);
//     let downloadLink = document.createElement('a');
//     downloadLink.href = pngUrl;
//     downloadLink.download = 'qr_vehicule.png';
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//     return false;
// };

// const CodeQR = ({ record }) => {
//     return <QRCode
//         id="qrcode"
//         value={"http://3.72.31.204:3000/#/vehicle/" + record.id + "/show"}
//         size={220}
//         level={'H'}
//         includeMargin={false}
//     />;
// };

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.show')}: {record ? `${record.plate_number}` : ''}</span>;

};

// const EventCreate = ({ record }) => {
//     return record['is_locked'] === "1" ? <div /> : <EventCreateButton record={record} redirect="show" />;

// };

// const EventEdit = ({ record }) => {
//     return <EventEditButton record={record} />;

// };


// const HtmlTextField = ({ source, record = {}, label }) => {
//     return <span class="MuiTypography-root MuiTypography-body2">{removeHTML(record[source])}</span>;
// }

// HtmlTextField.defaultProps = {
//     addLabel: true,
// };

// const BulkActionButtons = props => (
//     <Fragment>
//         <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer ces événements ?" />
//     </Fragment>
// );

// const EventPanel = ({ id, record, resource }) => {
//     const classes = useStyles();
//     console.log("File record " + JSON.stringify(record));


//     return(
//         <div style={styles.flexColumn}>
//         <span>Kilométrage au départ : {record.start_km}</span><br/>
//         <span>Kilométrage au retour : {record.end_km}</span><br/>
//         <span>Révision nécessaire : {record.revision === "1" ? "Oui" : "Non"}</span><br/>
//         <span>Commentaire : {removeHTML(record.comment)}</span><br/>
//         </div>
//     )
// };

// const EventFilter = (props) => (
//     <Filter {...props}>
//         {/* <ReferenceInput
//             label="Nom de Jockey"
//             source="user_code"
//             reference="driver"
//             // sort={{ field: 'user_code', order: 'ASC' }}
//             // filterToQuery={searchText => ({ user_code: searchText })}
//             allowEmpty={true}
//             alwaysOn
//         >
//             <AutocompleteInput optionText="name" />
//         </ReferenceInput> */}
//         <ReferenceInput label="Nom de Jockey" reference="driver" source="driver_id"   alwaysOn
//                       >
//                             <AutocompleteInput optionText="user_code" emptyText="Tous"/>
//                         </ReferenceInput>
//     </Filter>
// );

// const ListEvent = props  => (
// <List title=" " {...props} filter={{vehicle_id: props.id}} filters={<EventFilter />} exporter={false}  bulkActionButtons={<BulkActionButtons />}>
//                     <Datagrid  expandHeader={<div/>}>
//                         <FormattedDateField source="event_date" label="Date"  locales="fr-FR"/>
//                         {/* <TextField source="user_code" label = "Nom du Jockey assigné"/> */}
//                         <ReferenceField label="Nom du Jockey assigné" source="driver_id" reference="driver" link="show">
//                             <TextField source="user_code" />
//                         </ReferenceField>
//                         {/* <FormattedDateField source="start_time" label = "Heure de départ" format="HH:mm"/>
//                         <FormattedDateField source="end_time" label = "Heure de retour"  format="HH:mm"/> */}
//                         <TextField source="start_time" label = "Heure de départ"/>
//                         <TextField source="end_time" label = "Heure de retour"/>
//                         <TextField source="start_km" label = "Kilométrage au départ"/>
//                         <TextField source="end_km" label = "Kilométrage au retour"/>
//                         <ValidateField source="revision" label="Révision nécessaire"/>
//                         <HtmlTextField source="comment" label = "Commentaire"/>
//                         <EventEdit/>
//                     </Datagrid>
//                     </List>
// );

// const ListEventSmall = props  => (
//     <List title=" "  {...props} filter={{vehicle_id: props.id}} filters={<EventFilter />} exporter={false}  bulkActionButtons={<BulkActionButtons />}>
//                         <Datagrid expand={<EventPanel/>} expandHeader={<div/>}>
//                         <FormattedDateField source="event_date" label="Date"  locales="fr-FR"/>
//                         {/* <TextField source="user_code" label = "Nom du Jockey assigné"/> */}
//                         <ReferenceField label="Nom du Jockey assigné" source="driver_id" reference="driver" link="show">
//                             <TextField source="user_code" />
//                         </ReferenceField>
//                         {/* <FormattedDateField source="start_time" label = "Heure de départ" format="HH:mm"/>
//                         <FormattedDateField source="end_time" label = "Heure de retour"  format="HH:mm"/> */}
//                         <TextField source="start_time" label = "Heure de départ"/>
//                         <TextField source="end_time" label = "Heure de retour"/>
//                         <EventEdit/>

//                     </Datagrid>
//                         </List>
//     );

const OptionField = (record) => {

    const options = record.record.options;
    console.log("Vehicle options " + JSON.stringify(options));
    if (typeof (options) !== 'undefined' && options !== 'undefined' && options !== null && options.length > 0) {
        const value = options[0]['value'] === "1" ? "Oui" : "Non";
        return <span>{value}</span>
    }

    return <span>Non</span>;
}

OptionField.defaultProps = {
    addLabel: true,
};

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    // console.log("File record " + JSON.stringify(record));
    var files = record.files;
    const viewerId = record.id;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record} /> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <MediaViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record} /><DocApproveButton record={record} /></div> */}

        </div>
    )
};


const ShowVehicle = ({ staticContext, ...props }) => {
    const classes = useStyles();
    return <Show title={<Title />}  {...props} actions={<ShowActions />}>

        <TabbedShowLayout redirect="">
            <Tab label="Info">
                <TextField source="plate_number" label="Immatriculation" />
                {/* <TextField source="brand_name" label="Marque" />
                <TextField source="model_name" label="Modèle" />
                <TextField source="vehicle_type_name" label="Type" /> */}
                <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand" link="" perPage={9999999} className={classes.inlineBlock}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Modèle" source="model_id" reference="vehicle_model" link="" perPage={9999999} className={classes.inlineBlock}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="" perPage={9999999}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="seats" label="Nombre de passagers max." className={classes.inlineBlock} />
                <TextField source="color" label="Couleur" className={classes.inlineBlock} />
                {/* <ValidateField source="air_conditioner" label="Climatisation" /> */}
                {/* <OptionField label="Climatisation" /> */}
                <ValidateField source="air_conditioner" label="Climatisation" />
                <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" className={classes.inlineBlock} />
                <FormattedDateField source="control_date" label="Date de la prochaine visite technique" locales="fr-FR" className={classes.inlineBlock} />


                <ReferenceField label="Chauffeur" source="driver_id" reference="driver_all_type" link="" perPage={9999999}>
                    <TextField source="full_name" />
                </ReferenceField>
                {/* <ValidateField source="is_default" label="Par défaut" /> */}

                {/* <ValidateField source="is_default" label="Par défaut"/> */}
                {/* <DataStatusField source="status" label="État"/> */}
                {/* <ValidateField source="locked" label="Vérrouillé" /> */}
            </Tab>
            <Tab label="Documents">
                <ReferenceManyField reference="vehicle_doc" target="vehicle_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                        {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                        <DocNameField label="Nom du document" />
                        <FileCountField label="Fichiers" />
                        {/* <DocStatusField source="status" label="État" /> */}
                        {/* <DocStatusReasonField label="Motif de refus" /> */}
                        {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                        {/* <OpenFileButton/> */}

                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
}

    ;


export default ShowVehicle;