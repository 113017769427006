
import React from 'react';
import { ReferenceInput, CheckboxGroupInput, FormDataConsumer, ReferenceArrayInput,AutocompleteArrayInput,
    AutocompleteInput, TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, TextField, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'
import {ClientField, OrderStatusField, FormattedDateField, OrderTypeField} from '../../control/field/index'
import Typography from '@material-ui/core/Typography';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const driverNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_brand.edit')}: {record ? `${record.name}` : ''}</span>;
};

const EditOrderUnassigned = ({permissions, ...props })  => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>} 
            undoable={false}
            successMessage="La réservation a été assignée au chauffeur" >
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                
            <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/> */}
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            
            <TextField source="total" label="Prix(€)" className={classes.inlineBlock}/>
            <TextField source="commission_amount" label="Commission VTSafe(€)" className={classes.inlineBlock}/>
            <TextField source="driver_amount" label="Commission chauffeur(€)" className={classes.inlineBlock}/>
            
            <br/>
            <Typography variant="body2" className={classes.colorPrimary}>Assigner aux chauffeurs</Typography>

            {/* <ReferenceInput label="Chauffeur" source="driver_id" reference="driver">
                <AutocompleteInput optionText="full_name" />
            </ReferenceInput> */}
            <CheckboxGroupInput source="to_all_drivers" label="" choices={[
                { id: '1', name: 'Assigner à tous les chauffeurs en-ligne' },
            ]} />

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (typeof(formData.to_all_drivers) === 'undefined' ||
                    formData.to_all_drivers === 'undefined' ||
                    formData.to_all_drivers.length === 0 ||
                    formData.to_all_drivers[0] !== '1' || 
                    formData.to_all_drivers[0] !== 1) 
                    &&
                    <ReferenceArrayInput label="Chauffeurs en-ligne" reference="driver_online" source="driver_ids">
                        <AutocompleteArrayInput optionText={driverNameField}/>
                    </ReferenceArrayInput>
                 }
             </FormDataConsumer>

        </SimpleForm>
    </Edit>
)};

export default EditOrderUnassigned;