import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import OrderIcon from '@material-ui/icons/LibraryBooks';
// import VehicleIcon from '@material-ui/icons/DirectionsCar';
import VehicleIcon from '@material-ui/icons/DriveEta';
import MessageIcon from '@material-ui/icons/Message';
import ConfigurationIcon from '@material-ui/icons/Collections';
import OtherIcon from '@material-ui/icons/ScatterPlot';
import UserIcon from '@material-ui/icons/People';
import PriceIcon from '@material-ui/icons/Money';
import PromoCodeIcon from '@material-ui/icons/LocalOffer';
import Divider from '@material-ui/core/Divider';
import ServiceIcon from '@material-ui/icons/ScatterPlot';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReportIcon from '@material-ui/icons/BarChart';
import { withRouter } from 'react-router-dom';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    usePermissions,
    
} from 'react-admin';

// import drivers from '../../component/driver';
// import clients from '../../component/client';
// import vehicleTypes from '../../component/vehicle-type';
// import vehicleBrands from '../../component/vehicle-brand';
// import vehicleModels from '../../component/vehicle-model';
// import locations from '../../component/location';
// import order from '../../component/order-running';
// import orders from '../../component/order-running';

import SubMenu from './sub-menu';

const iconStyle = {
    color: 'white'

};

const textStyle = {
    fontSize: '14px',
    color: 'white'

};

const textBigStyle = {
    fontSize: '16px',
    color: 'white'

};

const bgStyle = {
    backgroundColor: '#000000',
    height: '100%'

};

const Menu = props => {
    // state = {
    //     menuAccounts: false,
    //     menuLocations: false,
    // };

    // static propTypes = {
    //     onMenuClick: PropTypes.func,
    //     logout: PropTypes.object,
    // };
    const { permissions } = usePermissions();
    const [menuOrders, setMenuOrders] = useState(false)
    const [menuAccounts, setMenuAccounts] = useState(false)
    const [menuConfiguration, setMenuConfiguration] = useState(false)
    const [menuPrices, setMenuPrices] = useState(false)
    const [menuVehicles, setMenuVehicles] = useState(false)
    const [menuReporting, setMenuReporting] = useState(false)
    const [menuServices, setMenuServices] = useState(false)

    const handleToggleMenuOrders = menu => {
        setMenuOrders(!menuOrders);
    };

    const handleToggleMenuReporting = menu => {
        setMenuReporting(!menuReporting);
    };

    const handleToggleMenuAccounts = menu => {
        setMenuAccounts(!menuAccounts);
    };

    const handleToggleMenuConfiguration = menu => {
        setMenuConfiguration(!menuConfiguration);
    };

    const handleToggleMenuVehicles = menu => {
        setMenuVehicles(!menuVehicles);
    };

    const handleToggleMenuPrices = menu => {
        setMenuPrices(!menuPrices);
    };

    const handleToggleMenuServices = menu => {
        setMenuServices(!menuServices);
    };

    // render() {
        // const translate = useTranslate();
        console.log("Menu permissions: " + permissions);
        const { onMenuClick, open, logout, translate } = props;
        return (
            <div style={bgStyle}>
                {' '}
                
                {/* <DashboardMenuItem onClick={onMenuClick} /> */}
                <MenuItemLink
                     leftIcon={<DashboardIcon style={iconStyle}/>}
                    style={textBigStyle}
                        to={`/`}
                        primaryText={translate(`menu.dashboard`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                <SubMenu
                    handleToggle={() => handleToggleMenuAccounts('menuAccounts')}
                    isOpen={menuAccounts}
                    sidebarIsOpen={open}
                    name={translate(`menu.accounts`)}
                    icon={<UserIcon />}
                >
                    <MenuItemLink
                      style={textStyle}
                        to={`/driver`}
                        primaryText={translate(`resources.driver.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    
                    <MenuItemLink
                      style={textStyle}
                        to={`/driver_company`}
                        primaryText={translate(`resources.driver_company.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                     style={textStyle}
                        to={`/driver_sub`}
                        primaryText={translate(`resources.driver_sub.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    

                    <MenuItemLink
                      style={textStyle}
                        to={`/client`}
                        primaryText={translate(`resources.client.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggleMenuOrders('menuOrders')}
                    isOpen={menuOrders}
                    sidebarIsOpen={open}
                    name="menu.orders"
                    icon={<OrderIcon/>}
                >

                    <MenuItemLink           
                      style={textStyle}
                        to={`/order_running`}
                        primaryText={translate(`resources.order_running.name`, {
                            smart_count: 2,
                        })}
                        
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                      style={textStyle}
                        to={`/order_finished`}
                        primaryText={translate(`resources.order_finished.name`, {
                            smart_count: 2,
                        })}
                        
                        onClick={onMenuClick}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggleMenuVehicles('menuVehicles')}
                    isOpen={menuVehicles}
                    sidebarIsOpen={open}
                    name="menu.vehicles"
                    icon={<VehicleIcon/>}
                    
                >
                     <MenuItemLink
                     style={textStyle}
                        to={`/vehicle`}
                        primaryText={translate(`resources.vehicle.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    {/* <MenuItemLink
                     style={textStyle}
                        to={`/car`}
                        primaryText={translate(`resources.car.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                       <MenuItemLink
                     style={textStyle}
                        to={`/tuktuk`}
                        primaryText={translate(`resources.tuktuk.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    /> */}

                    {/* <MenuItemLink
                        to={`/vehicle_type`}
                        primaryText={translate(`resources.vehicle_type.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    /> */}
                        <MenuItemLink
                          style={textStyle}
                        to={`/vehicle_brand`}
                        primaryText={translate(`resources.vehicle_brand.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                      style={textStyle}
                        to={`/vehicle_model`}
                        primaryText={translate(`resources.vehicle_model.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>

                {permissions === "Admin" && <SubMenu
                    handleToggle={() => handleToggleMenuPrices('menuPrices')}
                    isOpen={menuPrices}
                    sidebarIsOpen={open}
                    name="menu.prices"
                    icon={<PriceIcon/>}
                    
                >
                    <MenuItemLink
                      style={textStyle}
                        to={`/price`}
                        primaryText={translate(`resources.price_base.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                      style={textStyle}
                        to={`/price_time`}
                        primaryText={translate(`resources.price_time.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<vehicleTypes.icon />}
                        onClick={onMenuClick}
                    />
                    {/* <MenuItemLink
                        to={`/price_distance`}
                        primaryText={translate(`resources.price_distance.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<vehicleTypes.icon />}
                        onClick={onMenuClick}
                    /> */}
                    {/* <MenuItemLink
                        to={`/price_route`}
                        primaryText={translate(`resources.price_route.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<vehicleTypes.icon />}
                        onClick={onMenuClick}
                    /> */}

                    <MenuItemLink
                      style={textStyle}
                        to={`/promo_code`}
                        primaryText={translate(`resources.promo_code.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<PromoCodeIcon/>}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>}

                {/* <SubMenu
                    handleToggle={() => handleToggleMenuReporting('menuReporting')}
                    isOpen={menuReporting}
                    sidebarIsOpen={open}
                    name="menu.reporting"
                    icon={<ReportIcon/>}
                    
                >
                    <MenuItemLink
                    style={{'font-size':'14px'}}
                        to={`/gain`}
                        primaryText={translate(`resources.gain.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<MessageIcon/>}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                    style={{'font-size':'14px'}}
                        to={`/rate_system`}
                        primaryText={translate(`resources.rate_system.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<MessageIcon/>}
                        onClick={onMenuClick}
                    />
                     
                </SubMenu> */}

               
                <SubMenu
                    handleToggle={() => handleToggleMenuServices('menuServices')}
                    isOpen={menuServices}
                    sidebarIsOpen={open}
                    name="menu.services"
                    icon={<ServiceIcon/>}
                    
                >
                     <MenuItemLink
                    //  style={textStyle}
                    style={textStyle}
                        to={`/notice`}
                        primaryText={translate(`resources.notice.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<MessageIcon/>}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>
                {permissions === "Admin" && <SubMenu
                    handleToggle={() => handleToggleMenuConfiguration('menuConfiguration')}
                    isOpen={menuConfiguration}
                    sidebarIsOpen={open}
                    name="menu.configuration"
                    icon={<ConfigurationIcon/>}
                    
                >
                

                    <MenuItemLink
                      style={textStyle}
                        to={`/email_template`}
                        primaryText={translate(`resources.email_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                      style={textStyle}
                        to={`/sms_template`}
                        primaryText={translate(`resources.sms_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    />
                    
                      <MenuItemLink
                        style={textStyle}
                        to={`/config`}
                        primaryText={translate(`menu.settings`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}/>

                    {/* <MenuItemLink
                        to={`/name`}
                        primaryText={translate(`resources.name.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    /> */}
                    {/* <MenuItemLink
                        to={`/location`}
                        primaryText={translate(`resources.location.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    /> */}

                    {/* <MenuItemLink
                        to={`/cancel_category`}
                        primaryText={translate(`resources.cancel_category.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    /> */}

                    {/* 
                     <MenuItemLink
                        to={`/config`}
                        primaryText={translate(`menu.settings`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    /> */}

                    {/* <MenuItemLink
                        to={`/mail_template`}
                        primaryText={translate(`resources.mail_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/sms_template`}
                        primaryText={translate(`resources.sms_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    /> */}
                    


                </SubMenu>}
                 {/* } */}

                
                
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    // }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    // theme: state.theme,
    // locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);

// class Menu extends Component {
//     state = {
//         menuAccounts: false,
//         menuLocations: false,
//     };

//     static propTypes = {
//         onMenuClick: PropTypes.func,
//         logout: PropTypes.object,
//     };

//     handleToggle = menu => {
//         this.setState(state => ({ [menu]: !state[menu] }));
//     };

//     render() {
//         const { onMenuClick, open, logout, translate } = this.props;
//         return (
//             <div>
//                 {' '}
//                 {(permissions === "Admin" || permissions === "Client") && <DashboardMenuItem onClick={onMenuClick} />}
//                 <DashboardMenuItem onClick={onMenuClick} />
//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuAccounts')}
//                     isOpen={this.state.menuAccounts}
//                     sidebarIsOpen={open}
//                     name={translate(`menu.accounts`)}
//                     icon={<UserIcon />}
//                 >
//                     <MenuItemLink
//                         to={`/driver`}
//                         primaryText={translate(`resources.driver.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     {/* <MenuItemLink
//                         to={`/driver_company_deleted`}
//                         primaryText={translate(`resources.driver_company_deleted.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
//                     {/* <MenuItemLink
//                         to={`/driver`}
//                         primaryText={translate(`resources.driver.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
//                     {/* <MenuItemLink
//                         to={`/driver_deleted`}
//                         primaryText={translate(`resources.driver_deleted.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
//                     <MenuItemLink
//                         to={`/client`}
//                         primaryText={translate(`resources.client.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     {/* <MenuItemLink
//                         to={`/client_pro`}
//                         primaryText={translate(`resources.client_pro.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}

//                     {/* <MenuItemLink
//                         to={`/client_deleted`}
//                         primaryText={translate(`resources.client_deleted.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
//                 </SubMenu>

//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuOrders')}
//                     isOpen={this.state.menuOrders}
//                     sidebarIsOpen={open}
//                     name="menu.orders"
//                     icon={<OrderIcon/>}
//                 >

//                     {/* <MenuItemLink
//                         to={`/order_unassigned`}
//                         primaryText={translate(`resources.order_unassigned.name`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     /> */}

//                     <MenuItemLink           
//                         to={`/order_running`}
//                         primaryText={translate(`resources.order_running.name`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/order_finished`}
//                         primaryText={translate(`resources.order_finished.name`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     />
//                 </SubMenu>
//                 {/* <Divider /> */}
               
//                 {/* <Divider /> */}
//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuVehicles')}
//                     isOpen={this.state.menuVehicles}
//                     sidebarIsOpen={open}
//                     name="menu.vehicles"
//                     icon={<VehicleIcon/>}
                    
//                 >
//                  <MenuItemLink
//                         to={`/vehicle`}
//                         primaryText={translate(`resources.vehicle.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />

//                     {/* <MenuItemLink
//                         to={`/vehicle_type`}
//                         primaryText={translate(`resources.vehicle_type.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
//                     {/* <MenuItemLink
//                         to={`/vehicle_brand`}
//                         primaryText={translate(`resources.vehicle_brand.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/vehicle_model`}
//                         primaryText={translate(`resources.vehicle_model.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     /> */}
                    
//                 </SubMenu>

//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuPrices')}
//                     isOpen={this.state.menuPrices}
//                     sidebarIsOpen={open}
//                     name="menu.prices"
//                     icon={<PriceIcon/>}
                    
//                 >
//                     <MenuItemLink
//                         to={`/price`}
//                         primaryText={translate(`resources.price_base.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     {/* <MenuItemLink
//                         to={`/price_time`}
//                         primaryText={translate(`resources.price_time.name`, {
//                             smart_count: 2,
//                         })}
//                         // leftIcon={<vehicleTypes.icon />}
//                         onClick={onMenuClick}
//                     /> */}
//                     {/* <MenuItemLink
//                         to={`/price_distance`}
//                         primaryText={translate(`resources.price_distance.name`, {
//                             smart_count: 2,
//                         })}
//                         // leftIcon={<vehicleTypes.icon />}
//                         onClick={onMenuClick}
//                     /> */}
//                     <MenuItemLink
//                         to={`/price_route`}
//                         primaryText={translate(`resources.price_route.name`, {
//                             smart_count: 2,
//                         })}
//                         // leftIcon={<vehicleTypes.icon />}
//                         onClick={onMenuClick}
//                     />

//                     {/* <MenuItemLink
//                         to={`/promo_code`}
//                         primaryText={translate(`resources.promo_code.name`, {
//                             smart_count: 2,
//                         })}
//                         // leftIcon={<PromoCodeIcon/>}
//                         onClick={onMenuClick}
//                     /> */}
                    
//                 </SubMenu>

//                 {/* <SubMenu
//                     handleToggle={() => this.handleToggle('menuServices')}
//                     isOpen={this.state.menuServices}
//                     sidebarIsOpen={open}
//                     name="menu.services"
//                     icon={<ServiceIcon/>}
                    
//                 >
//                      <MenuItemLink
//                         to={`/notice`}
//                         primaryText={translate(`resources.notice.name`, {
//                             smart_count: 2,
//                         })}
//                         // leftIcon={<MessageIcon/>}
//                         onClick={onMenuClick}
//                     />
                    
//                 </SubMenu> */}

               
//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuConfiguration')}
//                     isOpen={this.state.menuConfiguration}
//                     sidebarIsOpen={open}
//                     name="menu.configuration"
//                     icon={<ConfigurationIcon/>}
                    
//                 >
//                     <MenuItemLink
//                         to={`/name`}
//                         primaryText={translate(`resources.name.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/location`}
//                         primaryText={translate(`resources.location.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />

//                     {/* <MenuItemLink
//                         to={`/cancel_category`}
//                         primaryText={translate(`resources.cancel_category.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     /> */}

//                     <MenuItemLink
//                         to={`/email_template`}
//                         primaryText={translate(`resources.email_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     />

//                     <MenuItemLink
//                         to={`/sms_template`}
//                         primaryText={translate(`resources.sms_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     />
//                      <MenuItemLink
//                         to={`/config`}
//                         primaryText={translate(`menu.settings`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />

//                     {/* <MenuItemLink
//                         to={`/mail_template`}
//                         primaryText={translate(`resources.mail_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     />

//                     <MenuItemLink
//                         to={`/sms_template`}
//                         primaryText={translate(`resources.sms_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     /> */}

//                 </SubMenu>
                
//                 {/* <Responsive
//                     xsmall={
//                         <MenuItemLink
//                             to="/configuration"
//                             primaryText={translate('pos.configuration')}
//                             leftIcon={<SettingsIcon />}
//                             onClick={onMenuClick}
//                         />
//                     }
//                     medium={null}
//                 /> */}
//                 <Responsive
//                     small={logout}
//                     medium={null} // Pass null to render nothing on larger devices
//                 />
//             </div>
//         );
//     }
// }

// const mapStateToProps = state => ({
//     open: state.admin.ui.sidebarOpen,
//     // theme: state.theme,
//     // locale: state.i18n.locale,
// });

// const enhance = compose(
//     withRouter,
//     connect(
//         mapStateToProps,
//         {}
//     ),
//     translate
// );

// export default enhance(Menu);
