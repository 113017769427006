
import React, { useState, useEffect } from 'react';
import {
    useNotify,
    usePermissions,
    TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm,
    SelectInput, AutocompleteInput, RadioButtonGroupInput, required, ImageInput, useTranslate, ReferenceInput
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { useForm } from 'react-final-form'
import BrandReferenceInput from './control/brand-reference-input';

import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { BACKEND_API_KEY, BACKEND_UMS_URL, locationTypes } from '../../util/variant'
import { hasWritePermissions } from '../../util/function';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.edit')}: {record ? `${record.name}` : ''}</span>;
};

const BrandSelectInput = (props) => {
    const form = useForm(); // Access the form state
    const notify = useNotify();

    // State để lưu danh sách subcategory
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);


    // Lấy giá trị hiện tại của category từ form
    const { values } = form.getState();
    var currentBrandId = values.brand_id;
    // console.log("Form values: " + JSON.stringify(values));
    // const selectedVehicleTypeId = values.vehicle_type_id;

    // Hàm để load danh sách subcategory dựa trên category
    const loadBrands = async (vehicleTypeId) => {
        if (!vehicleTypeId) {
            setBrands([]);
            // form.change('brand_id', null); // Reset subcategory khi không có category
            return;
        }

        setLoading(true);
        try {
            // Gọi API để lấy danh sách subcategory dựa trên category
            const url = BACKEND_UMS_URL + `/vehicle_brand?vehicle_type_id=${vehicleTypeId}&app_key=${BACKEND_API_KEY}`
            console.log("Load brands url: " + url);
            const sessionId = localStorage.getItem("session_id");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            });

            if (!response.ok) {
                throw new Error('Failed to load brands');
            }

            const result = await response.json();
            console.log("Load brands result: " + JSON.stringify(result));
            var data = result.data;

            // if (currentBrandId && !data.some((brand) => brand.id === currentBrandId)) {
            //     data.push({ id: currentBrandId, name: currentBrandId }); // Hiển thị giá trị đã lưu
            // }

            setBrands(data.map(item => ({ id: item.id, name: item.name })));
            const isValidBrand = data.some((brand) => brand.id === currentBrandId);
            if (!isValidBrand) {
                console.log("Not valid brand id");
                form.change('brand_id', null)
            }
            else {
                console.log("Valid brand id");
                form.change('brand_id', currentBrandId)
            }

        } catch (error) {
            console.log(error);
            notify('Error loading brands', 'warning');
            setBrands([]);

            // form.change('brand_id', null)
        } finally {
            setLoading(false);

        };
    };

    useEffect(() => {
        console.log('Form values:', form.getState().values);
        currentBrandId = values.brand_id;
    }, []);

    // Subscribe to changes in the category field
    useEffect(() => {
        const unsubscribe = form.subscribe(
            ({ values }) => {
                const newVehiceTypeId = values.vehicle_type_id;
                if (newVehiceTypeId !== selectedVehicleTypeId) {
                    setSelectedVehicleTypeId(newVehiceTypeId);
                }
            },
            { values: true } // Chỉ subscribe vào values
        );

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [form, selectedVehicleTypeId]);

    // Gọi loadSubcategories khi selectedCategory thay đổi
    useEffect(() => {
        loadBrands(selectedVehicleTypeId);
    }, [selectedVehicleTypeId]);

    useEffect(() => {
        const initialVehicleTypeId = form.getState().values.vehicle_type_id;
        if (initialVehicleTypeId && initialVehicleTypeId !== selectedVehicleTypeId) {
            setSelectedVehicleTypeId(initialVehicleTypeId)
        }
    }, [form]);

    return (
        <SelectInput
            {...props}
            choices={brands}
            disabled={loading || !selectedVehicleTypeId}
            optionText="name"
            optionValue="id"
            initialValue={currentBrandId}
        />
    );

}

const validateSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];

const EditVehicleModel = ({ classes, ...props }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;


    return (
        <Edit title={<Title />} {...props} actions={<EditActions />}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <BrandSelectInput source="brand_id" label="Marque" />
                <TextInput source="name" label={translate('name')} validate={required()} />
                {/* <BrandReferenceInput label="Marque" source="brand_id" reference="vehicle_brand" validate={required()}/> */}


                {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput> */}
                {/* <NumberInput source="seats" label = "Nombre de places" initialValue={1} step={1} validate={validateSeats}/> */}


                <RadioButtonGroupInput source="locked" label="Verrouillé?" choices={[
                    { id: 0, name: 'Non' },
                    { id: 1, name: 'Oui' },
                ]} initialValue="0" />

            </SimpleForm>
        </Edit>
    )
};

export default EditVehicleModel;