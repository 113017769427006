// export const BACKEND_URL = 'http://35.204.157.144/rest/v1/index.php/web';

import { LocationType } from '../util/constant'
import { createMuiTheme } from '@material-ui/core/styles';
// import error from '@material-ui/core/colors/red';
// import {ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_ATR, ORDER_ACTIVITY_TYPE_ANIMAL_ONLY, ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL,
//   ORDER_ACTIVITY_TYPE_PASSENGER_ONLY} from './constant'

export const ROOT_URL = 'https://api.wotro.app';
// export const ROOT_URL = 'http://13.39.251.224:3099';
// export const ROOT_UMS_URL = ROOT_URL + '/ums';
// export const ROOT_OMS_URL = ROOT_URL + '/oms';

// export const UNAUTH_UMS_URL = ROOT_UMS_URL + '/rest/v1/index.php/web';
// export const BACKEND_UMS_URL = UNAUTH_UMS_URL + '/auth';
export const UNAUTH_UMS_URL = ROOT_URL + '/web';
export const BACKEND_UMS_URL = UNAUTH_UMS_URL + '/auth';

// export const UNAUTH_OMS_URL = ROOT_OMS_URL + '/rest/v1/web';
// export const BACKEND_OMS_URL = UNAUTH_OMS_URL + '/auth';

export const BACKEND_API_KEY = 'wowb20840-j7865-jou3-2ab15-icu999e-8kxaq11jxp00';
export const GOOGLE_API_KEY = 'AIzaSyA3YthuceArqoWPnwrd2k5_pVugHLw-z4U';

export const PUSHER_API_KEY = 'e293b494613c7b9e2686';
export const PUSHER_CLUSTER = 'eu';
export const PUSHER_CHANNEL_USER = 'user_channel';
export const PUSHER_CHANNEL_ORDER = 'order_channel';
export const PUSHER_CHANNEL_LOCATION = 'location_channel';
export const EVENT_ONLINE_DRIVERS_CHANGED = 'online_drivers_changed';
export const EVENT_DRIVERS_LOCATION_CHANGED = 'drivers_location_changed';

export const EVENT_ORDER_NEW = 'order_new';


export const BACKEND_FILE_URL = 'https://storage.wotro.app';
// export const BACKEND_FILE_URL = 'http://13.39.251.224:3030';

export const rights = [
  { id: "Admin", name: "Admin" },
  { id: "ReadWrite", name: "Lecture et Écriture" },
  { id: "Read", name: "Lecture" },
];

export const locationTypes = [
  // {id: LocationType.AIRPORT, name: 'Aéroport'},
  // {id: LocationType.HOTEL, name: 'Hôtel'},
  // {id: LocationType.HOSPITAL, name: 'Hôpital'},
  { id: LocationType.PLACE, name: 'Lieu touristique' },
  { id: LocationType.SITE_TOURISTIC, name: 'Lieu prédéfini' },
];


export const locationDict = {
  // [LocationType.AIRPORT]:{id: LocationType.AIRPORT, name: 'Aéroport'},
  // [LocationType.STATION]:{id: LocationType.STATION, name: 'Gare'},
  // [LocationType.HOTEL]:{id: LocationType.HOTEL, name: 'Hôtel'},
  // [LocationType.HOSPITAL]:{id: LocationType.HOSPITAL, name: 'Hôpital'},
  [LocationType.PLACE]: { id: LocationType.PLACE, name: 'Lieu touristique' },
  [LocationType.SITE_TOURISTIC]: { id: LocationType.SITE_TOURISTIC, name: 'Lieu prédéfini' }
};


export const activityTypes = [
  // {id: ACTIVITY_TYPE_VTC, name: "VTC"}, {id: ACTIVITY_TYPE_ATR, name: "Transport léger des marchandises"}
];

export const orderActivityTypes = [
  // {id: ORDER_ACTIVITY_TYPE_PASSENGER_ONLY, name: "Passagers tout seul"}, 
  // {id: ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL, name: "Passagers avec un animal"}, 
  // {id: ORDER_ACTIVITY_TYPE_ANIMAL_ONLY, name: "Animaux non accompagnés"}, 
];

// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

export const theme = createMuiTheme({
  sidebar: {
    width: 300,
    // closedWidth: 200,
    backgroundColor: '#001b4c'
  },
  palette: {
    secondary: {
      // main: '#909090',
      main: '#f9fcfb',

    },
    primary: {
      // main: '#FCC814',
      // main: '#B3AD3B',
      // main: '#086AD4',
      main: '#3E884F',
    },
    error: { main: '#ff0000' },
    // contrastThreshold: 3,
    // tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    // MuiButton: { // override the styles of all instances of this component
    //     root: { // Name of the rule
    //         color: 'white', // Some CSS
    //     },
    // },
    MuiFilledInput: {
      root: {
        borderRadius: 0,
        backgroundColor: "#fff",
      },
    },
    RaSidebar: {
      root: {
        backgroundColor: '#fff',
        // height: '500%',
      },

    },

    // MuiTableHead: {
    //   root:{
    //     RaDataGrid:{
    //       thead:{
    //         display:'none'
    //       }
    //     }
    //   }

    // }
  },
});

// export const locationTypes = [
//     { name: 'orderNumber', title: 'Order', width: '10%',  type: 'string' },
//     { name: 'orderType', title: 'Type', width: '10%',  type: 'string' }
//   ];


// var locationTypes = [];
// locationTypes.push({id: LocationType.AIRPORT, name: 'Aéroport'});
// locationTypes.push({id: LocationType.STATION, name: 'Gare'});
// locationTypes.push({id: LocationType.PLACE, name: 'Endroit'});
// export lo
