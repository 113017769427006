
import React from 'react';
import { SelectInput, TextInput, SaveButton, Toolbar, Edit, SimpleForm } from 'react-admin';

import {
    required,
    email,

} from 'react-admin';
import { withStyles } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { ListButton } from 'react-admin';
import { rights } from '../../util/variant';
import { TextField } from '@material-ui/core';
import DeleteButton from '../../control/button/delete-button';

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource, id }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
        {/* {isMe && <DeleteButton />} */}
    </CardActions>
);

const Title = ({ record }) => {
    return <span>Compte opérateur: {record ? `${record.user_name}` : ''}</span>;
};

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const validateName = [required()];
const validatePassword = (value, allValues) => {
    if (!value) {
        return 'Veuillez saisir un mot de passe';
    }

    // if (value !== allValues.pasword_again) {
    //     return 'Mots de passes ne sont pas pareils';
    // }

    if (value !== allValues.password) {
        return 'Mots de passes ne sont pas pareils';
    }
    return undefined;
}

const RoleField = ({ record }) => {
    if (record === null || record === 'undefined') return <span />;
    if (record.role === 'Admin')
        return <SelectInput source="role" label="Droits" choices={rights} validate={[required()]} disabled />;
    return <SelectInput source="role" label="Droits" choices={rights} validate={[required()]} />;
};

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton undoable={false} /> */}
    </Toolbar>
));


const EditUser = ({ classes, ...props }) => {
    // console.log("Edit user props: " + JSON.stringify(props));
    // const { id } = props;
    // const { permissions } = usePermissions();
    // const { record } = props;
    // const { role } = record;
    // console.log("User role: " + record.role);
    return <Edit title={<Title />} {...props} actions=
        {
            <EditActions />
        }
    >
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
            <TextInput source="user_name" label="Identifiant"
                // formClassName={classes.inlineBlock} 
                validate={validateName}
            />
            <TextInput source="email" type="email" label="Email" validate={[required(), email()]} />
            {/* <SelectInput source="role" label="Droits" choices={rights} validate={[required()]} disabled /> */}
            <RoleField />
            {/* <TextInput source="first_name" label="Prénom" formClassName={classes.inlineBlock} validate={validateName} /> */}

            {/* <TextInput source="password" type="password" label="Mot de passe"
                validate={required()} />
            <TextInput source="password_again" type="password" label="Mot de passe confirmé"
                validate={[required(), validatePassword]} /> */}

        </SimpleForm>
    </Edit>
}
    ;

// export default withStyles(styles)(EditUser);
export default EditUser;