import React, { Fragment } from 'react';
import { List, Datagrid, TextField, SelectField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { rights } from '../../util/variant';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    return <span>Comptes</span>;
};

const ListUser = props => (
    <List title={<Title />} {...props} bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="user_name" label="Identifiant" />
            <TextField source="email" label="Email" />
            <SelectField source="role" label="Droits" choices={rights} />
            {/* <TextField source="first_name" label="Prénom"/>
            {permissions === 'Admin' && 
            <TextField source="agency.name" label="Agence"/>}
            <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
        </Datagrid>
    </List>
);

export default ListUser;

