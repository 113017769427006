import { fetchUtils } from 'react-admin';
import {BACKEND_API_KEY} from './variant'

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // const userType = localStorage.getItem('user_type');
    const sessionId = localStorage.getItem('session_id');
    console.log("Session id: " + sessionId);
    options.headers.set('session-id', sessionId);
    // options.headers.set('user-type', userType);

    if(url.includes('?'))
        url = url + "&app_key=" + BACKEND_API_KEY;
    else
        url = url + "?app_key=" + BACKEND_API_KEY;
    console.log("Request to url: " + url);
    console.log("Options: " + JSON.stringify(options));
    return fetchUtils.fetchJson(url, options);
  }

  
  export const getCurrentYear = () => {
      let currentYear = new Date().getFullYear();
      console.log('Current year ' + currentYear);
      return currentYear;
  }

  export const getCurrentMonth = () => {
    let currentMonth = new Date().getMonth() + 1;
    console.log('Current month ' + currentMonth);
    return currentMonth;
}

  export const getMonthOfYear = (year) => {
    if (typeof year === 'undefined' || !year)
        return [];

    var maxMonth = 12;
    if(year === getCurrentYear())
        maxMonth = getCurrentMonth();
    
    var months = [];
    for (var i = 1; i <= maxMonth; i++) 
        months.push({id: i, name: i});

    return months;
}

export const getWeeksOfMonth = (month, year) => {
    if (typeof month === 'undefined' || !month || 
        typeof year === 'undefined' || !year )
        return [];

    let weekNumber = getStartWeekNumber(month - 1, year);
    // let weeks = getWeeksStartAndEndInMonth(month - 1, year, 1);
    var date = new Date(year, month - 1, 1);
    var weekCount = 0;
    while(weekCount < 6){
        date.setDate(date.getDate() + 7);
        console.log("Date " + date + ' day: ' + date.getDay());
        if(date.getMonth() > month - 1){
            if(date.getDay() !== 1){//If this is not monday so it is in the last week of the month
                weekCount++;
                break;
            }
        }
            
        weekCount++;
    }
    
    var weekNumbers = [];
    // weekNumbers.push({id: weekNumber + weekCount, name: weekNumber + weekCount});
    for (var i = weekNumber; i <= weekNumber + weekCount; i++) 
        weekNumbers.push({id: i, name: i});

    return weekNumbers;
}

export const getYears = () => {
    var years = [];
    for (var i = getCurrentYear(); i >= 1945; i--) 
        years.push({id: i, name: i});

    return years;
}

export const getStartWeekNumber = (month, year) => {
     var tdt = new Date(year, month, 1);
     var dayn = (tdt.getDay() + 6) % 7;
     tdt.setDate(tdt.getDate() - dayn + 3);
     var firstThursday = tdt.valueOf();
     tdt.setMonth(0, 1);
     if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
     }
     return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

export const endFirstWeek = (firstDate, firstDay) => {
    if (! firstDay) {
        return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
        return firstDay - firstDate.getDay();
    } else {
        return 7 - firstDate.getDay() + firstDay;
    }
}

export const getWeeksStartAndEndInMonth = (month, year, start) => {
    let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();

    // let start = 1;
    let end = endFirstWeek(firstDate, 2);
    while (start <= numDays) {
        weeks.push({start: start, end: end});
        start = end + 1;
        end = end + 7;
        end = start === 1 && end === 8 ? 1 : end;
        if (end > numDays) {
            end = numDays;
        }
    }
    return weeks;
}

export const getUrlExtension = ( url ) => {
    return url.split(/\#|\?/)[0].split('.').pop().trim();
}

export const generateRandom = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const removeHTML = (str) => { 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
}