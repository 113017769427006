import React, { Fragment } from 'react';
import { CardActions, Pagination, ReferenceField, ReferenceInput, AutocompleteInput, CreateButton, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';

import BatchDeleteButton from '../../control/button/batch-delete-button';
import { BACKEND_API_KEY, BACKEND_UMS_URL, locationDict, locationTypes } from '../../util/variant'
import { ValidateField, FormattedDateField, DataStatusField } from '../../control/field/index'

import { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
// import { useForm } from 'react-final-form'; // reac

const VehicleFilter = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    // const form = useForm(); // Để truy cập và cập nhật giá trị filter
    const { filterValues, setFilters } = props; // L

    // State để lưu danh sách subcategory
    const [models, setModels] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [loadingModels, setLoadingModels] = useState(false);

    // Danh sách category (có thể lấy từ API hoặc hardcode)
    // const categories = [
    //     { id: 'electronics', name: 'Electronics' },
    //     { id: 'clothing', name: 'Clothing' },
    // ];

    // Lấy giá trị hiện tại của category từ form
    // const { values } = form.getState();
    // const selectedBrandId = values.brand_id;
    const selectedVehicleTypeId = filterValues.vehicle_type_id;
    const selectedBrandId = filterValues.brand_id;

    // Hàm để load danh sách subcategory dựa trên category
    const loadModels = async (vehicleTypeId, brandId) => {
        if (!brandId || !vehicleTypeId) {
            setModels([]);
            // form.change('model_id', null); // Reset subcategory khi không có category
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, model_id: undefined }); // Res
            return;
        }

        setLoadingModels(true);
        try {
            // Gọi API để lấy danh sách subcategory dựa trên category
            const url = BACKEND_UMS_URL + `/vehicle_model?brand_id=${brandId}&app_key=${BACKEND_API_KEY}`
            console.log("Load models url: " + url);
            const sessionId = localStorage.getItem("session_id");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            });

            if (!response.ok) {
                throw new Error('Failed to load models');
            }

            const result = await response.json();
            console.log("Load models result: " + JSON.stringify(result));
            const { data } = result;
            setModels(data.map(item => ({ id: item.id, name: item.name })));
            // form.change('model_id', null); // Reset subcategory khi danh sách thay đổi
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, model_id: undefined }); // Res
        } catch (error) {
            console.log(error);
            notify('Error loading models', 'warning');
            setModels([]);
            // form.change('model_id', null);
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, model_id: undefined }); // Res
        } finally {
            setLoadingModels(false);
        }
    };

    const loadBrands = async (vehicleTypeId) => {
        if (!vehicleTypeId) {
            setBrands([]);
            setModels([]);
            // form.change('model_id', null); // Reset subcategory khi không có category
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined, model_id: undefined }); // Res
            return;
        }

        setLoadingBrands(true);
        try {
            // Gọi API để lấy danh sách subcategory dựa trên category
            const url = BACKEND_UMS_URL + `/vehicle_brand?vehicle_type_id=${vehicleTypeId}&app_key=${BACKEND_API_KEY}`
            console.log("Load brands url: " + url);
            const sessionId = localStorage.getItem("session_id");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            });

            if (!response.ok) {
                throw new Error('Failed to load brands');
            }

            const result = await response.json();
            console.log("Load brands result: " + JSON.stringify(result));
            const { data } = result;
            setBrands(data.map(item => ({ id: item.id, name: item.name })));
            setModels([]);
            // form.change('model_id', null); // Reset subcategory khi danh sách thay đổi
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined, model_id: undefined }); // Res
        } catch (error) {
            console.log(error);
            notify('Error loading brands', 'warning');
            setBrands([]);
            setModels([]);
            // form.change('model_id', null);
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined, model_id: undefined }); // Res
        } finally {
            setLoadingBrands(false);
        }
    };

    // Gọi loadSubcategories khi selectedCategory thay đổi
    // useEffect(() => {
    //     loadModels(selectedBrandId);
    // }, [selectedBrandId]);
    useEffect(() => {
        loadBrands(selectedVehicleTypeId);
        // loadModels(selectedBrandId);
    }, [selectedVehicleTypeId,]);

    useEffect(() => {
        loadModels(selectedVehicleTypeId, selectedBrandId);
        // loadModels(selectedBrandId);
    }, [selectedVehicleTypeId, selectedBrandId,]);

    return (
        <Filter {...props}>
            {/* <SelectInput
                source="category"
                label="Category"
                choices={categories}
                alwaysOn
                allowEmpty
                emptyText="All Categories"
                
            /> */}
            <TextInput label="Plaque" source="plate_number" alwaysOn resettable />
            <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
                reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
                alwaysOn>
                <SelectInput
                    optionText='name' emptyText="Tous"

                />
            </ReferenceInput>
            {/* <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
                sort={{ field: 'name', order: 'ASC' }}
                reference="vehicle_brand" perPage={99999} alwaysOn resettable>
                <AutocompleteInput optionText="name"

                />
            </ReferenceInput> */}
            <SelectInput
                source="brand_id"
                label="Marque"
                choices={brands}
                alwaysOn
                allowEmpty
                emptyText="Toutes les marques"
                disabled={loadingBrands || !selectedVehicleTypeId}
            />
            <SelectInput
                source="model_id"
                label="Modèle"
                choices={models}
                alwaysOn
                allowEmpty
                emptyText="Tous les modèles"
                disabled={loadingModels || !selectedBrandId || !selectedVehicleTypeId}
            />
        </Filter>
    );
};

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);

const ListActions = ({ basePath }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    { id: 0, name: 'Non-vérrouillé' },
    { id: 1, name: 'Vérrouillé' }
];

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Plaque" source="plate_number" alwaysOn resettable />
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput>
        <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_brand" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        <ReferenceInput label="Modèle" source="model_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_model" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput>
        {/* 
        <ReferenceInput label="Chauffeur" source="driver_id" reference="driver" perPage={99999} emptyText="Tous" resettable alwaysOn>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput> */}

        {/* <SelectInput label="État" source="status" choices={statusList} alwaysOn
            allowEmpty emptyText="Tous les états" emptyValue='-1' initialValue='-1'
        /> */}
        {/* <SelectInput label="Vérrouillage" source="locked" choices={lockeds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
        {/* <SelectInput label="Validation" source="validated" choices={validateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

// const DriverActions = ({
//     bulkActions,
//     basePath,
//     currentSort,
//     displayedFilters,
//     exporter,
//     filters,
//     filterValues,
//     onUnselectItems,
//     resource,
//     selectedIds,
//     showFilter,
//     total
// }) => (
//     <CardActions>
//         {bulkActions && React.cloneElement(bulkActions, {
//             basePath,
//             filterValues,
//             resource,
//             selectedIds,
//             onUnselectItems,
//         })}
//         {filters && React.cloneElement(filters, {
//             resource,
//             showFilter,
//             displayedFilters,
//             filterValues,
//             context: 'button',
//         })}
//         <ExportFileButton filterValues={filterValues} resource={resource} basePath={basePath} />
//     </CardActions>
// );

const ListVehicle = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <List pagination={<ListPagination />} title={<Title />} {...props} perPage={50}
            filters={<VehicleFilter props={props} />}
            // actions={<DriverActions/>}
            bulkActionButtons={<BulkActionButtons />} exporter={false} resource="vehicle">
            <Datagrid rowClick="show">
                <TextField source="plate_number" label="Plaque" />
                {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" 
                        source="model_id" reference="vehicle_model"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField> */}

                <TextField source="brand_name" label="Marque" />
                <TextField source="model_name" label="Modèle" />
                {/* <TextField source="vehicle_type_name" label="Type"/> */}
                {/* <TextField source="color" label="Couleur"/> */}
                {/* <TextField source="color" label="Couleur"/> */}
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField> */}
                <TextField source="color" label="Couleur" />
                <TextField source="seats" label="Nombre de passagers max." />
                {/* <TextField source="luggages" label="Nombre de bagages max." /> */}
                <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" />
                <FormattedDateField source="control_date" label="Date du prochain contrôle technique" locales="fr-FR" />

                {/* <ValidateField source="is_default" label="Par défaut" /> */}
                {/* <DataStatusField source="status" label="" /> */}
                {/* <ValidateField source="is_locked" label="Vérrouillé" /> */}
            </Datagrid>
        </List>);
};

export default ListVehicle;

