import React, { Fragment } from 'react';
import { usePermissions, ReferenceField, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { OrderTypeField, OrderTypeFilter, OrderActivityTypeField } from '../../control/field/index'
import { hasAdminPermissions } from '../../util/function';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}

    </Filter>
);

const ListPrice = ({ ...props }) => {
    const { permissions } = usePermissions();
    const hasPermissions = hasAdminPermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;

    return <List title={<Title />} {...props}
        filters={<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            {/* <TextField source="vehicle_type_name" label="Type de véhicule" /> */}
            {/* <OrderTypeField source="order_type" label="Type de commande"/> */}
            {/* <OrderActivityTypeField label="Type de pause"/> */}
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                <TextField source="name" />
            </ReferenceField>

            <TextField source="min" label="Minimum(Franc CFA)" />
            <TextField source="base" label="Base(Franc CFA)" />
            <TextField source="per_km" label="Franc CFA/km" />
            <TextField source="per_minute" label="Franc CFA/minute" />
            {/* <TextField source="wait" label="Attente(€/minute)"/>
            <TextField source="markup" label="Supplément(€)"/> */}
            {/* <TextField source="quantity_markup" label="Supplément au dela 3 passagers(€/passager)"/> */}
        </Datagrid>
    </List>
};

export default ListPrice;

