export const DRIVER_STATUS_FREE = 0;
export const DRIVER_STATUS_BUSY = 1;
export const DRIVER_STATUS_OFFLINE = 2;

export const UB_DOC_TYPE_VEHICLE_TYPE_MAP = "181";
export const UB_DOC_TYPE_VEHICLE_TYPE_SELECT = "182";

export const GENDER_MALE = "1";
export const GENDER_FEMALE = "2";

export const TRUE = 1;
export const FALSE = 0;

export const USER_TYPE_CLIENT = 1;
export const USER_TYPE_CLIENT_PRO = 12;
export const USER_TYPE_AUTO_ENTERPRENER = "2";
export const USER_TYPE_COMPANY_AND_DRIVER = "6";
export const USER_TYPE_DRIVER_IN_COMPANY = "7";
export const USER_TYPE_DRIVER_COMPANY = "246";


export const ACTIVITY_TYPE_VTC = "2";
export const ACTIVITY_TYPE_ATR = "4";

export const ORDER_ACTIVITY_TYPE_WITH_PAUSE = "1";
export const ORDER_ACTIVITY_TYPE_NO_PAUSE = "0";

export const ANIMAL_TYPE_FREE = "2";
export const ANIMAL_TYPE_CAGE = "4";

// export const STATION = 1;
// export const AIRPORT = 2;
// export const AIRPORT_TERMINAL = 4;
// export const PLACE = 3;
// export const REGION = 5;
// export const CLUBS = 8;
// export const BOOK = 6;
// export const RECENT = 7;

export const weekDays = [
    'Lundi', 'Mardi', 'Mercred            i', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'
];

export const unitTypes = [
    { id: 0, name: 'Franc CFA' },
    { id: 1, name: '%' }
];

export const markupTypes = [
    { id: 0, name: 'Majoration (+)' },
    { id: 1, name: 'Remise (-)' },

];

export const OrderType = {
    IMMEDIATE: 0,
    RESERVATION: 1,
    CIRCUIT_NO_PAUSE: 2,
    CIRCUIT_NO_PAUSE_RESERVATION: 5,
    CIRCUIT_WITH_PAUSE: 3,
    CIRCUIT_WITH_PAUSE_RESERVATION: 6,
};

export const orderTypes = [
    { id: OrderType.IMMEDIATE, name: "Immédiate" },
    { id: OrderType.RESERVATION, name: "Réservation à l'avance" },
    { id: OrderType.CIRCUIT_NO_PAUSE, name: "Circuit sans pause - Immédiate " },
    { id: OrderType.CIRCUIT_NO_PAUSE_RESERVATION, name: "Circuit sans pause - Réservation " },
    { id: OrderType.CIRCUIT_WITH_PAUSE, name: "Circuit avec pause - Immédiate" },
    { id: OrderType.CIRCUIT_WITH_PAUSE_RESERVATION, name: "Circuit avec pause - Réservation" },
];



export const OrderStatus = {
    CREATED: 1 << 0,
    ASSIGNED: 1 << 1,
    DENIED: 1 << 2,
    ACCEPTED: 1 << 3,
    TO_PICK: 1 << 4,
    AT_PICK: 1 << 5,
    EXTEND_WAIT: 1 << 6,
    ABSENT: 1 << 7,
    ONBOARD: 1 << 8,
    STARTED: 1 << 9,
    ENDED: 1 << 10,
    FINISHED: 1 << 11,
    CANCELLED: 1 << 12,
    UNPAID: 1 << 13,
    DELETED_BY_CLIENT: 1 << 14,
    DELETED_BY_DRIVER: 1 << 15,
    RATED_BY_CLIENT: 1 << 16,
    RATED_BY_DRIVER: 1 << 21,
    ACCEPTED_BY_OPERATOR: 1 << 17,
    DENIED_BY_OPERATOR: 1 << 18,
    WAIT_PAYMENT: 1 << 20,
}
// export const LocationType = {
//     STATION: 1,
//     AIRPORT: 2,
//     AIRPORT_TERMINAL: 4,
//     PLACE: 3,
//     REGION: 5,
//     CLUBS: 8,
//     BOOK: 6,
//     RECENT: 7,
// }

export const LocationType = {
    STATION: 1,
    AIRPORT: 2,
    AIRPORT_TERMINAL: 4,
    PLACE: 3,
    REGION: 5,
    CLUBS: 8,
    BOOK: 6,
    RECENT: 7,
    HOTEL: 10,
    HOSPITAL: 12,
    SITE_TOURISTIC: 14,
}

export const DataStatus = {
    NEW: "0",
    APPROVED: "1",
    REJECTED: "2",

}

export const Status = {
    UNLOCKED: "0",
    LOCKED: "1",
}

// Date.prototype.addDays = function(days) {
//     var date = new Date(this.valueOf());
//     date.setDate(date.getDate() + days);
//     return date;
// }

// var date = new Date();