import React from 'react';
import { Edit, TextInput, TextField, SimpleForm, required, email } from 'react-admin';

import Button from '@material-ui/core/Button';
import { CardActions, ShowButton, SaveButton, Toolbar } from 'react-admin';

const ProfileEditActions = ({ basePath, data, resource }) => (
    <CardActions>
        <ShowButton basePath='/profile' record={data} />
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
);

const ProfileEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="ENREGISTRER"
            redirect="/profile"
            submitOnEnter={true}
        />

    </Toolbar>
);

// const userId = localStorage.getItem('user_id');
const role = localStorage.getItem('role');

// const EditProfile = ({ staticContext, ...props }) => {
const EditProfile = props => {
    return (

        <Edit
            actions={<ProfileEditActions />}
            /*
                As we are not coming for a route generated by a Resource component,
                we have to provide the id ourselves.
                As there is only one config for the current user, we decided to
                hardcode it here
            */
            id="noid"
            /*
                For the same reason, we need to provide the resource and basePath props
                which are required by the Edit component
            */
            resource="profile"
            basePath="/profile"
            // redirect="false"
            title="Mon profil"
            {...props}
        >

            <SimpleForm toolbar={<ProfileEditToolbar />} >
                <TextInput source="user_name" label="Identifiant" validate={required()} />
                <TextInput source="email" label="Email" validate={[required(), email()]} />

                {/* <TextInput source="first_name" label="Prénom" validate={required()} /> */}

            </SimpleForm>
        </Edit>
    );
};

export default EditProfile;
