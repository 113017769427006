// import Create from './create' 
import Edit from './edit'
import List from './list'
import Show from './show'
import PriceIcon from '@material-ui/icons/Money';

export default {

    edit: Edit,
    list: List,
    // show: Show,
    icon: PriceIcon,
}