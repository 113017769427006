
import React from 'react';
import { usePermissions, TextInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, ReferenceInput, AutocompleteInput, required, useTranslate }
    from 'react-admin';
import { hasWritePermissions } from '../../util/function';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_brand.create')}</span>;
};

const CreateVehicleBrand = props => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;



    return (
        <Create title={<Title />} {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" label={translate('name')} />

                <RadioButtonGroupInput source="locked" label="Verrouillé?"
                    choices={[
                        { id: '0', name: 'Non' },
                        { id: '1', name: 'Oui' },
                    ]} initialValue='0' />
            </SimpleForm>
        </Create>
    )
};

export default CreateVehicleBrand;
