
import React from 'react';
import {
    usePermissions,
    FormDataConsumer,
    CheckboxGroupInput, ReferenceArrayInput,
    AutocompleteArrayInput, TextInput, RadioButtonGroupInput,
    Create, SimpleForm, SelectInput, required, useTranslate
}
    from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Typography from '@material-ui/core/Typography';
import { hasWritePermissions } from '../../util/function';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.notice.create')}</span>;
};

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="body2">Laissez le champs Chauffeurs/Clients vide si vous voulez envoyer le message à tous les chauffeurs/clients.</Typography>

        {/* <Typography variant="body2">
            Posts will only be published once an editor approves them
        </Typography> */}
    </div>
);

const recipientNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';

const CreateNotice = props => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>

    return (
        <Create title={<Title />} {...props}>
            <SimpleForm redirect="list">
                <TextInput multiline source="title" label="Titre" fullWidth={true} validate={required()} />
                <RichTextInput multiline source="content" label="Contenu" fullWidth={true} validate={required()} />

                <CheckboxGroupInput source="to_all_drivers" label="" choices={[
                    { id: '1', name: 'Envoyer à tous les chauffeurs' }
                ]} />

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) =>
                        (typeof (formData.to_all_drivers) === 'undefined' ||
                            formData.to_all_drivers === 'undefined' ||
                            formData.to_all_drivers.length === 0 ||
                            formData.to_all_drivers[0] !== 1) &&
                        <ReferenceArrayInput {...rest} label="Chauffeurs" reference="driver" source="driver_ids"
                        >
                            <AutocompleteArrayInput optionText={recipientNameField} />
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>

                <CheckboxGroupInput source="to_all_clients" label="" choices={[
                    { id: '1', name: 'Envoyer à tous les clients' }
                ]} />

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) =>
                        (typeof (formData.to_all_clients) === 'undefined' ||
                            formData.to_all_clients === 'undefined' ||
                            formData.to_all_clients.length === 0 ||
                            formData.to_all_clients[0] !== 1) &&
                        <ReferenceArrayInput label="Clients" reference="client" source="client_ids" resettable>
                            <AutocompleteArrayInput optionText={recipientNameField} />
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>

                <RadioButtonGroupInput source="published" label="Publié?"
                    choices={[
                        { id: '0', name: 'Non' },
                        { id: '1', name: 'Oui' },
                    ]} initialValue='0' />
            </SimpleForm>
        </Create>
    )
};

export default CreateNotice;
