import React, { Fragment } from 'react';
import { ReferenceInput, usePermissions, CardActions, ReferenceField, CreateButton, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';

import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { ValidateField, AvatarField } from '../../control/field/index'
import { hasWritePermissions } from '../../util/function';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);


const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const ListActions = ({ basePath, data, resource }) => {
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return null;
    return <CardActions style={cardActionStyle}>
        <CreateButton basePath={basePath} />
    </CardActions>
};


const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_brand.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    { id: 0, name: 'Non-verrouillé' },
    { id: 1, name: 'Verrouillé' }
];

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Nom" source="name" alwaysOn resettable />
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput>
        <SelectInput label="État" source="locked" alwaysOn choices={statusList}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'
        />

    </Filter>
);

const ListVehicleBrand = ({ permissions, ...props }) => (
    <React.Fragment>
        <List title={<Title />} {...props} actions={<ListActions />} bulkActionButtons={<BulkActionButtons />}
            filters={<ListFilter permissions={permissions} />}
            exporter={false}>
            <Datagrid rowClick="show">
                <TextField source="name" label="Nom" />
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" linkType="">
                    <TextField source="name" />
                </ReferenceField>
                <ValidateField source="locked" label="Verrouillée" />
                {/* <EditButton/> */}
            </Datagrid>
        </List>
        {/* <Route
           path="/vehicle_brand/create"
           render={() => (
               <CreateVehicleBrand {...props} />
           )} */}

    </React.Fragment>
);

export default ListVehicleBrand;

