
import React from 'react';
import { number, ReferenceField, NumberInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { orderTypes } from '../../util/constant'
import { activityTypes, orderActivityTypes } from '../../util/variant'
import { OrderActivityTypeField, OrderTypeField, ValidateField } from '../../control/field';
// import {AddPriceTimeButton} from '../../button/field';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },

});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
            <DeleteButton label="ra.action.delete" redirect='list' {...props} />}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);

const Title = ({ record }) => {
    // var string = "";
    // if(record){
    //     const vehicleTypeName = record ? record.vehicle_type_name : '';
    //     const orderType = record ? record.order_type : 0;
    //     if(orderType == OrderType.IMMEDIATE)
    //         string = vehicleTypeName + " - Immédiate";
    //     else
    //         string = vehicleTypeName + " - Réservation à l'avance";
    // }
    const translate = useTranslate();
    // return <span>{translate('resources.price.show')} pour: {record ? `${string}` : ''}</span>;
    return <span>{translate('resources.price.show')}</span>;

};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

const EditPrice = ({ permissions, ...props }) => {
    const classes = useStyles();
    if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
        <Edit title={<Title />} {...props} actions={<EditActions />}>

            {/* <TabbedForm redirect="list" 
                toolbar={<CustomToolbar/>}>
                    <FormTab label="Configuration de base"> */}
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                {/* <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_type" label="Type de véhicule">
                            <SelectInput optionText="name"/>
                        </ReferenceInput> */}
                {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={orderActivityTypes} className={classes.inlineBlock}/> */}
                {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                            choices={orderTypes} className={classes.inlineBlock}/>
                        <div/>
                         */}
                {/* <OrderTypeField label="Type de commande"/> */}
                {/* <OrderActivityTypeField label="Type de pause"/> */}
                {/* <TextField source="vehicle_type_name" label="Type de véhicule" /> */}
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                <TextInput source="min" label="Minimum(Franc CFA)" validate={validatePriceAdditional} className={classes.inlineBlock} />
                <TextInput source="base" label="Base(Franc CFA)" validate={validatePriceAdditional} className={classes.inlineBlock} />
                <div />
                <TextInput source="per_km" label="Par km(Franc CFA)" validate={validatePriceAdditional} className={classes.inlineBlock} />
                <TextInput source="per_minute" label="Par minute(Franc CFA)" validate={validatePriceAdditional} className={classes.inlineBlock} />
                <div />
                {/* <TextInput source="wait" label="Attente(€/minute)" validate={validatePriceAdditional}/> */}
                {/* <TextInput source="markup" label="Supplément(€)" validate={validatePriceAdditional}/> */}
                {/* <TextInput source="markup" label="Supplément fixe(€)" validate={validatePriceAdditional}/>
            <TextInput source="quantity_markup" label="Supplément > 3 passagers(€/pax)" validate={validatePriceAdditional} style={{'width':'50%'}}/> */}
            </SimpleForm>
            {/* </FormTab>
                <FormTab label="Majorité/réduction d'horaire">
                    <ReferenceManyField pagination={<Pagination />} reference="price_time" target="price_id" addLabel={false}>
                        <Datagrid>
                            <ValidateField source="every_day" label="Tous les jours"/>
                            <TextField source="day" label="Dates"/>
                            <TextField source="markup" label="Majorité/Réduction"/>
                            <TextField source="from_time" label="Heure de début"/>
                            <TextField source="to_time" label="Heure de fin"/>
                            <EditButton basePath="/price_time"/>
                        </Datagrid>
                    </ReferenceManyField>
                    

                </FormTab>
                <FormTab label="Forfait">
                    <ReferenceManyField pagination={<Pagination />} reference="price_route" target="price_id" addLabel={false}>
                        <Datagrid expandHeader={<div/>}>
                            <TextField source="from_id" label="From"/>
                            <TextField source="to_id" label="To"/>
                            <TextField source="per_package" label="Prix(€)"/>
                            
                            
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab> */}
            {/* </TabbedForm> */}
        </Edit>
    )
};

export default EditPrice;