import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import decodeJwt from 'jwt-decode';
import { UNAUTH_UMS_URL, BACKEND_API_KEY } from '../util/variant'
import { CODE_OK } from '../util/constant';
import { isEmpty } from '../util/function';

const publicRoutes = ['/login', '/forgot-password'];

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const app_key = BACKEND_API_KEY;
        console.log("Login body = " + JSON.stringify({ username, password, app_key }));
        const request = new Request(UNAUTH_UMS_URL + '/login?app_key=' + app_key, {
            method: 'POST',
            body: JSON.stringify({ username, password, app_key }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                // if (response.status < 200 || response.status >= 300) {
                //     throw new Error(response.statusText);
                // }

                if (response.status < 200 || response.status >= 300) {
                    // throw new Error(response.statusText);
                    console.log("statusText = " + response.message);
                    return Promise.reject(new Error(response.message));
                }

                // response.json().then(json => {
                //     const {token, data} = json;
                //     localStorage.setItem('user_id', data._id);
                //     const decodedToken = decodeJwt(token);
                //     localStorage.setItem('token', token);
                //     localStorage.setItem('role', decodedToken.role);

                //     console.log("Login response data: " + JSON.stringify(data));
                //     localStorage.setItem('profile', data);

                //     console.log("Login response json: " + JSON.stringify(json));
                //     return json;
                //   });
                const json = response.json();
                // console.log("Login response json: " + JSON.stringify(json));
                // if (isEmpty(json)) {
                //     return Promise.reject(new Error("L'identifiant ou le mot de passe est invalide"));
                // }

                // if (json['code'] !== CODE_OK) {
                //     return Promise.reject(new Error(json['message']));
                // }
                return json;
                // return response.json();
            })
            // .then(({ token, data }) => {
            //     const decodedToken = decodeJwt(token);
            //     localStorage.setItem('token', token);

            //     let role = decodedToken.role;
            //     localStorage.setItem('role', role);
            //     // let roleLabel = role == 'Admin' ? ' - Administrateur' : '';
            //     // localStorage.setItem('role_labe', roleLabel);

            //     console.log("Login user id: " + decodedToken.sub);
            //     localStorage.setItem('user_id', decodedToken.sub);

            //     console.log("User profile: " + JSON.stringify(data));
            //     localStorage.setItem('profile', data);
            //     localStorage.setItem('email', data.email);
            //     return Promise.resolve();
            // })
            .then(({ data }) => {
                if (isEmpty(data)) {
                    return Promise.reject(new Error("L'identifiant ou le mot de passe est invalide"));
                }

                console.log("User profile: " + JSON.stringify(data));
                console.log("Session id: " + data.session_id);
                localStorage.setItem('session_id', data.session_id);
                localStorage.setItem('user_id', data.id);
                localStorage.setItem('role', data.role);
                localStorage.setItem('profile', data);
                localStorage.setItem('email', data.user_name);
                return Promise.resolve();
            })
            ;
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('role');
        localStorage.removeItem('user_id');
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        // if (status === 401 || status === 403) {
        if (status === 401) {
            localStorage.removeItem('session_id');
            localStorage.removeItem('role');
            localStorage.removeItem('user_id');
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        // console.log("Auth check params " + JSON.stringify(params));
        // const { pathname } = window.location;
        const pathname = window.location.hash ? window.location.hash.replace('#', '') : '/';
        console.log("Auth check path name = " + pathname);
        if (publicRoutes.includes(pathname))
            return Promise.reject();
        return localStorage.getItem('session_id') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        // const { pathname } = window.location;
        const pathname = window.location.hash ? window.location.hash.replace('#', '') : '/';
        console.log("Auth get permission path name = " + pathname);
        if (publicRoutes.includes(pathname)) {
            return Promise.resolve("guest");
        }
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();

    }

    return Promise.reject('Unknown method');
}