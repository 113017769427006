import React, { useState } from 'react';
// import { Create, TextInput, TextField, SimpleForm, required } from 'react-admin';
import { connect } from 'react-redux';
import { Button, Box, TextField, TextInput, Typography, CircularProgress } from '@material-ui/core';
import { BACKEND_API_KEY, UNAUTH_UMS_URL } from '../../util/variant';
// import { CardActions, ShowButton, SaveButton, Toolbar, userLogout } from 'react-admin';

// const ChangePasswordActions = ({ basePath, data, resource }) => (
//     <CardActions>
//         {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
//         {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
//     </CardActions>
// );

// const ChangePasswordToolbar = props => (
//     <Toolbar {...props} >
//         <SaveButton
//             label="ENREGISTRER"
//             redirect="/login"
//             submitOnEnter={true}
//         />

//     </Toolbar>
// );

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

// const validatePassword = (value, allValues) => {
//     if (!value) {
//         return 'Veuillez saisir un mot de passe';
//     }

//     // if (value !== allValues.pasword_again) {
//     //     return 'Mots de passes ne sont pas pareils';
//     // }

//     if (value !== allValues.password) {
//         return 'Les nouveaux mots de passe ne sont pas identiques';
//     }
//     return undefined;
// }


// const EditProfile = ({ staticContext, ...props }) => {
const ForgotPassword = ({ history }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        // const body = { 'email': email, 'app_key': BACKEND_API_KEY };
        const app_key = BACKEND_API_KEY;

        try {
            const response = await fetch(UNAUTH_UMS_URL + "/password", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, app_key })
            });

            if (!response.ok) {
                // throw new Error('Failed to send password reset email. Please try again.');
                throw new Error("Erreur survenue lors d'envoyer l'email pour la réinitialisation du mot de passe. Merci de réessayer plus tard");
            }

            const data = await response.json();
            setSuccess(data.message || "Un lien de réinitialisation du mot de passe a été envoyé sur votre email");
            setTimeout(() => {
                history.push('/login');
            }, 2000);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (

        <Box p={3} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
                Mot de passe oublié ?
            </Typography>
            <Typography variant="body1" gutterBottom>
                Merci de saisir votre email pour réinitialiser le mot de passe de votre compte
            </Typography>

            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                />
                {error && (
                    <Typography color="error" variant="body2" style={{ marginTop: 8 }}>
                        {error}
                    </Typography>
                )}
                {success && (
                    <Typography color="primary" variant="body2" style={{ marginTop: 8 }}>
                        {success}
                    </Typography>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="#3E884F"
                    fullWidth
                    style={{ marginTop: 16 }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Envoyer'}
                </Button>
            </form>
            <Button
                variant="text"
                color="textSecondary"
                style={{ marginTop: 16 }}
                onClick={() => history.push('/login')}
                disabled={loading}
            >
                Retour à la connexion
            </Button>
        </Box>
    );
};

export default ForgotPassword;
// export default connect(undefined, { userLogout })(ResetPassword);

