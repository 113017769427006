
import React from 'react';
import {number, ReferenceInput, TextInput, Create, SimpleForm, SelectInput, required, useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes, ACTIVITY_TYPE_TAXI, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_TPMR} from '../../util/constant'
import {activityTypes} from '../../util/variant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price_distance.create')}</span>;
};

const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

const CreatePrice = props => {
    const classes = useStyles();
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        {/* <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
            reference="vehicle_type" label="Type de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput> */}
            <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/>
            <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/>
            
            <TextInput source="from_km" label="De(km)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="to_km" label="À(km)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="per_km" label="Prix par km(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            
        </SimpleForm>
    </Create>
)};

export default CreatePrice;

{/* options={{ format: 'dd/MM/yyyy' }  */}