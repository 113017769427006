
import React, { Link } from 'react';
import { SimpleShowLayout, TextField, Show, } from 'react-admin';
import { EditButton } from 'react-admin';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';


// const cardActionStyle = {
//     zIndex: 2,
//     display: 'inline-block',
//     float: 'right',
// };

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const changePassword = () => {
    window.location.href = '/#/password';
}

const ProfileActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath='/edit-profile' record={data} />
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

// const userId = localStorage.getItem('user_id');
const role = localStorage.getItem('role');

const ShowProfile = ({ staticContext, ...props }) => (
    <Show actions={<ProfileActions />} {...props}
        title="Mon profil"
        resource="profile"
        id="noid"
        basePath="/profile"
    >
        {/* <span>{"Hei"}</span><br/> */}
        <SimpleShowLayout>
            {/* <TextField source="last_name" label="Nom"/> */}
            {/* <TextField source="first_name" label="Prénom"/> */}
            <TextField source="user_name" label="Identifiant" />
            <TextField source="email" label="Email" />


        </SimpleShowLayout>
    </Show>
);


export default ShowProfile;