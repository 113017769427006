
import React, { useState, useEffect } from 'react';
import {
    useNotify,
    usePermissions,
    TextInput, minValue, NumberInput, ImageInput, ReferenceInput,
    ImageField, AutocompleteInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate
}
    from 'react-admin';
import { useForm } from 'react-final-form'

import BrandReferenceInput from './control/brand-reference-input';
import { hasWritePermissions } from '../../util/function';
import { BACKEND_API_KEY, BACKEND_UMS_URL } from '../../util/variant';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.create')}</span>;
};

const BrandSelectInput = (props) => {
    const form = useForm(); // Access the form state
    const notify = useNotify();

    // State để lưu danh sách subcategory
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);

    // Lấy giá trị hiện tại của category từ form
    // const { values } = form.getState();
    // console.log("Form values: " + JSON.stringify(values));
    // const selectedVehicleTypeId = values.vehicle_type_id;

    // Hàm để load danh sách subcategory dựa trên category
    const loadBrands = async (vehicleTypeId) => {
        if (!vehicleTypeId) {
            setBrands([]);
            form.change('brand_id', null); // Reset subcategory khi không có category
            return;
        }

        setLoading(true);
        try {
            // Gọi API để lấy danh sách subcategory dựa trên category
            const url = BACKEND_UMS_URL + `/vehicle_brand?vehicle_type_id=${vehicleTypeId}&app_key=${BACKEND_API_KEY}`
            console.log("Load brands url: " + url);
            const sessionId = localStorage.getItem("session_id");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            });

            if (!response.ok) {
                throw new Error('Failed to load brands');
            }

            const result = await response.json();
            console.log("Load brands result: " + JSON.stringify(result));
            const { data } = result;
            setBrands(data.map(item => ({ id: item.id, name: item.name })));
            form.change('brand_id', null)
        } catch (error) {
            console.log(error);
            notify('Error loading brands', 'warning');
            setBrands([]);

            form.change('brand_id', null)
        } finally {
            setLoading(false);

        };
    };

    useEffect(() => {
        console.log('Form values:', form.getState().values);
    }, []);

    // Subscribe to changes in the category field
    useEffect(() => {
        const unsubscribe = form.subscribe(
            ({ values }) => {
                const newVehiceTypeId = values.vehicle_type_id;
                if (newVehiceTypeId !== selectedVehicleTypeId) {
                    setSelectedVehicleTypeId(newVehiceTypeId);
                }
            },
            { values: true } // Chỉ subscribe vào values
        );

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [form, selectedVehicleTypeId]);

    // Gọi loadSubcategories khi selectedCategory thay đổi
    useEffect(() => {
        loadBrands(selectedVehicleTypeId);
    }, [selectedVehicleTypeId]);

    return (
        <SelectInput
            {...props}
            choices={brands}
            disabled={loading || !selectedVehicleTypeId}
            optionText="name"
            optionValue="id"
        />
    );

}

const validateSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];

const CreateVehicleModel = props => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;

    return (
        <Create title={<Title />} {...props}>
            <SimpleForm redirect="list">

                {/* <BrandReferenceInput label="Marque" source="brand_id" reference="vehicle_brand" validate={required()}/> */}
                <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    {/* <AutocompleteInput optionText="name" /> */}
                    <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>

                {/* <ReferenceInput label="Marque" source="brand_id" perPage={99999}
                        sort={{ field: 'name', order: 'ASC' }}
                        reference="vehicle_brand" validate={required()}>
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput> */}
                <BrandSelectInput source="brand_id" label="Marque" />

                <TextInput source="name" label={translate('name')} validate={required()} />

                {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput> */}
                {/* <NumberInput source="seats" label = "Nombre de places" initialValue={1} step={1} validate={validateSeats}/> */}

                <RadioButtonGroupInput source="locked" label="Verrouillé?" choices={[
                    { id: '0', name: 'Non' },
                    { id: '1', name: 'Oui' },
                ]} initialValue="0" />
            </SimpleForm>
        </Create>
    )
};

export default CreateVehicleModel;
