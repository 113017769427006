import React from 'react';
import { Create, TextInput, TextField, SimpleForm, required } from 'react-admin';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { CardActions, ShowButton, SaveButton, Toolbar, userLogout } from 'react-admin';

const ChangePasswordActions = ({ basePath, data, resource }) => (
    <CardActions>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
);

const ChangePasswordToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="ENREGISTRER"
            redirect="/login"
            submitOnEnter={true}
        />

    </Toolbar>
);

const userId = localStorage.getItem('user_id');
const role = localStorage.getItem('role');

const validatePassword = (value, allValues) => {
    if (!value) {
        return 'Veuillez saisir un mot de passe';
    }

    // if (value !== allValues.pasword_again) {
    //     return 'Mots de passes ne sont pas pareils';
    // }

    if (value !== allValues.password) {
        return 'Les nouveaux mots de passe ne sont pas identiques';
    }
    return undefined;
}


// const EditProfile = ({ staticContext, ...props }) => {
const ChangePassword = props => {
    return (

        <Create
            actions={<ChangePasswordActions />}
            /*
                As we are not coming for a route generated by a Resource component,
                we have to provide the id ourselves.
                As there is only one config for the current user, we decided to
                hardcode it here
            */

            /*
                For the same reason, we need to provide the resource and basePath props
                which are required by the Edit component
            */
            resource="password"
            basePath="/password"
            // redirect="false"
            title="Changer le mot de passe"
            redirect={userLogout}
            {...props}
        >

            <SimpleForm toolbar={<ChangePasswordToolbar />} >
                <TextInput autoComplete="x-password" source="current_password" type="password" label="Mot de passe actuel" style={{ 'width': '400px' }} />
                <TextInput autoComplete="x-password" source="password" type="password" label="Nouveau mot de passe" style={{ 'width': '400px' }} />
                <TextInput autoComplete="x-password" source="confirm_password" type="password" label="Confirmer le nouveau mot de passe" style={{ 'width': '400px' }}
                    validate={[required(), validatePassword]} />

            </SimpleForm>
        </Create>
    );
};

// export default ChangePassword;
export default connect(undefined, { userLogout })(ChangePassword);

