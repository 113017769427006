import React from 'react'
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import * as moment from 'moment';

const FormattedDateField = ({ record = {}, source, label, locales, classes, format }) => {
    // const classes = useStyles();
    const translate = useTranslate();
    if (typeof (record) === 'undefined' ||
        typeof (record[source]) === 'undefined'
        || record[source] === "" || record[source] === null)
        return <Typography variant="body2">---</Typography>

    if (typeof (format) === 'undefined' ||
        format === 'undefined' || format === '')
        format = 'format_date';

    const value = moment(record[source], "YYYY-MM-DD HH:mm:ss");
    return <Typography variant="body2">{value.format(translate(format))}</Typography>

}

FormattedDateField.defaultProps = {
    addLabel: true,
};

export default FormattedDateField;