
import React from 'react';
import {
    CheckboxGroupInput, FormDataConsumer, NumberInput, ReferenceArrayInput, AutocompleteArrayInput, TextInput,
    RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, number, useTranslate
}
    from 'react-admin';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { generateRandom } from "../../util/function"
import { unitTypes } from '../../util/constant'
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';


const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.promo_code.create')}</span>;
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit être >= 0';
    }
    return [];
};


const validateGreaterThanZero = (value, allValues) => {
    if (value < 1) {
        return 'Doit être au moins 1';
    }
    return [];
};

const validateAmountMin = [required(), number(), validateNotNegative];
const validateValue = [required(), number(), validatePositive];
const validateMaxCount = [required(), number(), validateGreaterThanZero];

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="body2">Mettez #code dans le titre ou la description si vous voulez le code apparaît dans le titre ou la description respectivement</Typography>

        {/* <Typography variant="body2">
            Posts will only be published once an editor approves them
        </Typography> */}
    </div>
);

const clientNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';

const CreatePromoCode = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
        <Create title={<Title />} {...props}>
            <SimpleForm redirect="list">

                <TextInput source="code" label="Code" initialValue={generateRandom(5)} validate={required()} />
                <TextInput source="name" label="Nom" validate={required()} fullWidth={true} />
                <TextInput source="description" label="Description" multiline="true" fullWidth={true} validate={required()} />
                <NumberInput source="value" label="Valeur" min={0.01} step={0.01} validate={validateValue} />
                <RadioButtonGroupInput source="unit_type" label="Unité" choices={unitTypes} initialValue={0} />
                {/* <NumberInput defaultValue="0" source="amount_min" label = "Montant min. de commande pour appliquer (€)" style={{'width': '400px'}} min={0.00} step={0.01} validate={validateAmountMin}/> */}

                {/* <RadioButtonGroupInput source="per_client_count" label="Nombre max. d'utlisation global ou par client ?" 
            choices={[
                { id: '0', name: 'Global' },
                { id: '1', name: 'Par client' },
            ]} initialValue='0'/>

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (formData.per_client_count === '0') &&
                    <NumberInput source="max_count" label = "Nombre maximum d'utlisation global" min={0} step={1} initialValue={0} style={{'width': '400px'}}
                validate={validateMaxCount}
            />

                 }
             </FormDataConsumer> */}

                {/* <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                 (formData.per_client_count === '1') && */}
                <NumberInput source="max_count" label="Nombre maximum d'utlisation par client" min={1} step={1} initialValue={1} style={{ 'width': '400px' }}
                    validate={validateMaxCount}
                />
                {/* }
             </FormDataConsumer> */}



                <MuiPickersUtilsProvider utils={MomentUtils}>

                    <DateTimeInput source="begin_time" label="Date de début de validité"
                        options={{ format: 'DD/MM/YYYY: HH:mm' }}
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} />
                    {/* <DateTimeInput source="begin_date" label="Date de début" 
                    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} /> */}
                    <DateTimeInput source="end_time" label="Date de fin de validité"
                        // options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} 
                        // validate={required()}/>
                        options={{ format: 'DD/MM/YYYY: HH:mm' }}
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} />
                </MuiPickersUtilsProvider>

                <CheckboxGroupInput source="to_all_clients" label="" choices={[
                    { id: '1', name: 'Envoyer à tous les clients' },
                ]} />

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) =>
                        (typeof (formData.to_all_clients) === 'undefined' ||
                            formData.to_all_clients === 'undefined' ||
                            formData.to_all_clients.length === 0 ||
                            formData.to_all_clients[0] !== 1) &&
                        <ReferenceArrayInput label="Clients" reference="client" source="client_ids" perPage={999999}>
                            <AutocompleteArrayInput optionText={clientNameField} />
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>

                {/* <CheckboxGroupInput source="publish_to" label="Moyens de distribution" choices={[
                 { id: '1', name: 'Via emails' }, { id: '2', name: 'Via messages' },
            ]} /> */}

                <RadioButtonGroupInput source="published" label="Publié?"
                    choices={[
                        { id: '0', name: 'Non' },
                        { id: '1', name: 'Oui' },
                    ]} initialValue='0' />


                {/* 
            <DateTimeInput source="begin_date" label="Date de debut"
            autoComplete="" options={{ format: 'dd/MM/yyyy HH:mm' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <DateTimeInput source="end_date" label="Date de fin"
            autoComplete="" options={{ format: 'dd/MM/yyyy HH:mm' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            </SimpleForm>
        </Create>
    )
};

export default CreatePromoCode;
