
import React, { useState, useEffect } from 'react';
import { useNotify, usePermissions, FormDataConsumer, NumberInput, CheckboxGroupInput, SimpleFormIterator, ReferenceInput, TextInput, Create, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { TimeInput } from 'react-admin-date-inputs2';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton } from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import { orderTypes, markupTypes, unitTypes } from '../../util/constant'
import { activityTypes } from '../../util/variant'
import { hasAdminPermissions } from '../../util/function';
// import { formValueSelector } from 'redux-form';
import { useForm } from 'react-final-form'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },

});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price_time.create')}</span>;
};

const DaysField = ({ record }) => {
    // console.log('Edit time price record: ' + JSON.stringify(record));
    const [checkEveryDay, setCheckEveryDay] = useState(false);

    // props.record.every_day[0] === '1';

    const onChangeEveryDay = (e) => {
        // alert(e.target.checked);
        // alert('xxx');
        setCheckEveryDay(!checkEveryDay);
    }

    return <div><CheckboxGroupInput source="every_day" label="" choices={[
        { id: '1', name: 'Tous les jours' },
    ]} onChange={(e) => onChangeEveryDay(e)} />

        <FormDataConsumer subscription={{ values: true }}>

            {({ formData, ...rest }) =>
                !checkEveryDay &&
                <CheckboxGroupInput source="days" label="" choices={[
                    { id: 1, name: 'Lundi' },
                    { id: 2, name: 'Mardi' },
                    { id: 3, name: 'Mercredi' },
                    { id: 4, name: 'Jeudi' },
                    { id: 5, name: 'Vendredi' },
                    { id: 6, name: 'Samedi' },
                    { id: 7, name: 'Dimanche' },
                ]} />
            }
        </FormDataConsumer>
    </div>
}


const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);

// const VehicleClassSelectInput = (props) => {
//     const form = useForm(); // Access the form state
//     const notify = useNotify();

//     // State để lưu danh sách subcategory
//     const [brands, setBrands] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);

//     // Lấy giá trị hiện tại của category từ form
//     // const { values } = form.getState();
//     // console.log("Form values: " + JSON.stringify(values));
//     // const selectedVehicleTypeId = values.vehicle_type_id;

//     // Hàm để load danh sách subcategory dựa trên category
//     const loadBrands = async (vehicleTypeId) => {
//         if (!vehicleTypeId) {
//             setBrands([]);
//             form.change('brand_id', null); // Reset subcategory khi không có category
//             return;
//         }

//         setLoading(true);
//         try {
//             // Gọi API để lấy danh sách subcategory dựa trên category
//             const url = BACKEND_UMS_URL + `/vehicle_brand?vehicle_type_id=${vehicleTypeId}&app_key=${BACKEND_API_KEY}`
//             console.log("Load brands url: " + url);
//             const sessionId = localStorage.getItem("session_id");
//             const response = await fetch(url, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'session-id': sessionId
//                 },
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to load brands');
//             }

//             const result = await response.json();
//             console.log("Load brands result: " + JSON.stringify(result));
//             const { data } = result;
//             setBrands(data.map(item => ({ id: item.id, name: item.name })));
//             form.change('brand_id', null)
//         } catch (error) {
//             console.log(error);
//             notify('Error loading brands', 'warning');
//             setBrands([]);

//             form.change('brand_id', null)
//         } finally {
//             setLoading(false);

//         };
//     };

//     useEffect(() => {
//         console.log('Form values:', form.getState().values);
//     }, []);

//     // Subscribe to changes in the category field
//     useEffect(() => {
//         const unsubscribe = form.subscribe(
//             ({ values }) => {
//                 const newVehiceTypeId = values.vehicle_type_id;
//                 if (newVehiceTypeId !== selectedVehicleTypeId) {
//                     setSelectedVehicleTypeId(newVehiceTypeId);
//                 }
//             },
//             { values: true } // Chỉ subscribe vào values
//         );

//         // Cleanup subscription on unmount
//         return () => unsubscribe();
//     }, [form, selectedVehicleTypeId]);

//     // Gọi loadSubcategories khi selectedCategory thay đổi
//     useEffect(() => {
//         loadBrands(selectedVehicleTypeId);
//     }, [selectedVehicleTypeId]);

//     return (
//         <SelectInput
//             {...props}
//             choices={brands}
//             disabled={loading || !selectedVehicleTypeId}
//             optionText="name"
//             optionValue="id"
//         />
//     );

// }

const CreatePriceTime = ({ ...props }) => {
    const classes = useStyles();
    // if (permissions !== 'Admin') return <span>Aucune permission</span>;
    const { permissions } = usePermissions();
    const hasPermissions = hasAdminPermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;
    return (

        <Create title={<Title />} {...props} actions={<CreateActions />}>
            <SimpleForm redirect="list">
                <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
                    reference="vehicle_type" label="Type de véhicule">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.vehicle_type_id === 1 &&
                        //   <TextInput source="email" {...rest} />
                        <ReferenceInput {...rest} source="vehicle_class_id" perPage={99999} className={classes.inlineBlock}
                            reference="vehicle_class" label="Gamme de véhicule">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    }
                </FormDataConsumer>



                {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/> */}
                {/* <SelectInput source="activity_type" label="Type de pause" validate={required()} 
                        choices={activityTypes} className={classes.inlineBlock}/> */}

                <div />
                {/* <ArrayInput source="configuration">
                <SimpleFormIterator> */}
                {/* <CheckboxGroupInput source="days" choices={[
                        { id: 0, name: 'Tous les jours' },
                        { id: 1, name: 'Lundi' },
                        { id: 2, name: 'Mardi' },
                        { id: 3, name: 'Mercredi' },
                        { id: 4, name: 'Jeudi' },
                        { id: 5, name: 'Vendredi' },
                        { id: 6, name: 'Samedi' },
                        { id: 7, name: 'Dimanche' },
                    ]} /> */}
                <DaysField />

                <MuiPickersUtilsProvider utils={MomentUtils}>

                    <TimeInput source="from_time" label="Heure de début"
                        options={{ format: 'HH:mm' }} />


                    <TimeInput source="to_time" label="Heure de fin"
                        options={{ format: 'HH:mm' }} />

                </MuiPickersUtilsProvider>
                {/* <SelectInput source="type" label="Type" validate={required()} choices={markupTypes} defaultValue="0"/> */}
                <NumberInput source="markup" label="Valeur" />
                <SelectInput source="markup_unit" label="Unité" validate={required()} choices={unitTypes} defaultValue="0" />
                {/* </SimpleFormIterator> */}
                {/* </ArrayInput> */}

            </SimpleForm>
        </Create>
    )
};

export default CreatePriceTime;

{/* options={{ format: 'dd/MM/yyyy' }  */ }