import React from 'react';
// import logo from './logo.svg';
import './App.css';

import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import VehicleIcon from '@material-ui/icons/DriveEta';
// import UserIcon from '@material-ui/icons/People';
import ClientIcon from '@material-ui/icons/EmojiPeople';
import DriverIcon from '@material-ui/icons/Person';

import DescriptionIcon from '@material-ui/icons/Description';
import LocationIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
// import frenchMessages from 'ra-language-french';
// import englishMessages from 'ra-language-english';
// import englishMessages from './i18n/fr';
import frenchMessages from './i18n/fr';

// import englishMessages from 'ra-language-english';
// import frenchMessages from 'ra-language-french';

// domain translations
// import * as domainMessages from './i18n';

import polyglotI18nProvider from 'ra-i18n-polyglot';

import Foo from './component/foo'
import gains from './component/gain/index'
import vehicles from './component/vehicle/index'

import notices from './component/notice/index'
import options from './component/option/index'
import cancelCategories from './component/cancel-category/index'
import prices from './component/price/index'
import priceTimes from './component/price-time/index'
import priceRoutes from './component/price-route/index'
import priceDistances from './component/price-distance/index'
import clients from './component/client/index'
import rateSystems from './component/rate-system/index'
import clientsDeleted from './component/client-deleted/index'
import drivers from './component/driver/index'
import driverSubs from './component/driver-sub/index'
import driversDeleted from './component/driver-deleted/index'
import driverCompanies from './component/driver-company/index'
import driverCompaniesDeleted from './component/driver-company-deleted/index'
import ordersRunning from './component/order-running/index'
import ordersFinished from './component/order-finished/index'
import ordersUnassigned from './component/order-unassigned/index'
import ordersClient from './component/order-client/index'
import ordersDriver from './component/order-driver/index'
import locations from './component/location/index'
import vehicleTypes from './component/vehicle-type/index'
import vehicleBrands from './component/vehicle-brand/index'
import vehicleModels from './component/vehicle-model/index'
import promoCodes from './component/promo-code/index'
import emailTemplates from './component/template-email/index'
import smsTemplates from './component/template-sms/index'
import EditConfig from './component/config/edit'

import Login from './component/auth/login';
import NewLayout from './control/layout/new-layout';
// import Foo from './component/foo';
// import DocPreview from './component/doc/preview';
// import PdfShow from './control/pdfviewer/pdf-show';
// import ImageShow from './control/imageviewer/image-show';
// import { createMuiTheme } from '@material-ui/core/styles';

// import {httpClient} from './util/function'
import { theme } from './util/variant'

import Dashboard from './component/dashboard/dashboard';
import authProvider from './provider/auth-provider';
import superDataProvider from './provider/super-data-provider';
import uploadFileProvider from './provider/upload-file-provider';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const dataProvider = uploadFileProvider(superDataProvider);

// const ums = '/ums/rest/v1/index.php/web';
// const oms = '/oms/rest/v1/index.php/web';

// const clientResource = ums + '/client';
// const clientDeletedResource = ums + '/client_deleted';
// const driverResource = ums + '/driver';
// const driverDeletedResource = ums + '/driver_deleted';
// const driverOnlineResource = ums + '/driver_online';
// const locationResource = ums + '/location';
// const vehicleResource = ums + '/vehicle';
// const vehicleTypeResource = ums + '/vehicle_type';
// const vehicleBrandResource = ums + '/vehicle_brand';
// const vehicleModelResource = ums + '/vehicle_model';
// const validationResource = ums + '/validation';
// const docResource = ums + '/doc';
// const cardResource = ums + '/card';
// const orderResource = ms + '/order';

const App = () =>
  <Admin
    theme={theme}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Login}
    locale='fr'
    i18nProvider={i18nProvider}
    customRoutes={[
      // <Route path="/change-password" component={Profile.ChangePassword}/>,
      // <Route path="/my-profile" component={Profile.EditProfile}/>,
      // <Route path="/view-my-profile" component={Profile.ShowProfile}/>,
      // <Route path="/pdf/:url" component={PdfShow}/>,
      // <Route path="/image/:url" component={ImageShow}/>,
      // <Route path="/preview/:selected" component={DocPreview}/>,
      // <Route path="/foo" component={Foo}/>,
      <Route path="/config" component={EditConfig} />
    ]}
    layout={NewLayout}
  >

    {permissions => [
      <Resource name="config" edit={EditConfig} />,

      <Resource name="sms_template" {...smsTemplates} />,
      <Resource name="email_template" {...emailTemplates} />,
      <Resource name="price_time" {...priceTimes} />,
      <Resource name="rate_system" {...rateSystems} />,
      <Resource name="price" {...prices} />,
      <Resource name="vehicle" {...vehicles} />,

      <Resource name="order_running" {...ordersRunning} />,
      <Resource name="order_finished" {...ordersFinished} />,
      <Resource name="order_client" {...ordersClient} />,
      <Resource name="order_driver" {...ordersDriver} />,

      <Resource name="gain" {...gains} />,
      <Resource name="driver" {...drivers} />,
      <Resource name="client" {...clients} />,
      <Resource name="driver_company" {...driverCompanies} />,
      <Resource name="driver_sub" {...driverSubs} />,
      permissions === 'Admin' ? <Resource name="location" {...locations} /> : null,
      <Resource name="promo_code" {...promoCodes} />,
      <Resource name="vehicle_brand" {...vehicleBrands} />,
      <Resource name="vehicle_model" {...vehicleModels} />,
      <Resource name="vehicle" {...vehicles} />,
      <Resource name="vehicle_type" {...vehicleTypes} />,


      <Resource name="notice" {...notices} />,
      <Resource name="validation" />,
      <Resource name="doc" />,
      <Resource name="invoice" />,
      <Resource name="doc_date" />,
      <Resource name="doc_status" />,

      <Resource name="vehicle_doc" />,

      <Resource name="vehicle_event" />,
      <Resource name="card" />,
      <Resource name="order" />,
      <Resource name="order_stop" />,
      <Resource name="driver_online" />,
      <Resource name="order_client" />,
      <Resource name="order_driver" />,
      <Resource name="profile_photo" />,
      <Resource name="wallet_action" />,

      <Resource name="wallet" />,
      <Resource name="driver_all_type" />,
      <Resource name="driver_favorite" />,
    ]}
  </Admin>

// <Resource name="vehicle_date"/>,
//       <Resource name="vehicle_date"/>,
// <Resource name="price_route" {...priceRoutes} />,
//   <Resource name="price_distance" {...priceDistances} />,
{/* <Resource name="order_unassigned" {...ordersUnassigned} />, */ }
{/* <Resource name="driver_in_company" {...drivers}/>, */ }
{/* <Resource name="promo_code" {...promoCodes} />, */ }

{/* <Resource name="promo_code" {...promoCodes} />,
    <Resource name="option" {...options} />,
    <Resource name="notice" {...notices} />,
<Resource name="vehicle_type" {...vehicleTypes} />,
    <Resource name="vehicle_brand" {...vehicleBrands} />,
    <Resource name="vehicle_model" {...vehicleModels} />,
    <Resource name="cancel_category" {...cancelCategories} />,
    <Resource name="client_pro" {...clientsPro} />,
    
    <Resource name="driver_company" {...driverCompanies} />,
    <Resource name="driver_deleted" {...driversDeleted} />,
    <Resource name="driver_company_deleted" {...driverCompaniesDeleted} />,
    <Resource name="client_deleted" {...clientsDeleted} />,
    <Resource name="customer_settings"/>, */}
export default App;
