
import React from 'react';
import { TextInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate }
    from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_brand.create')}</span>;
};

const CreateVehicleBrand = props => {
    const translate = useTranslate();

    return (
        <Create title={<Title />} {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" label={translate('name')} />
                <RadioButtonGroupInput source="locked" label="Verrouillé?"
                    choices={[
                        { id: '0', name: 'Non' },
                        { id: '1', name: 'Oui' },
                    ]} initialValue='0' />
            </SimpleForm>
        </Create>
    )
};

export default CreateVehicleBrand;
