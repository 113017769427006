import React, { Fragment } from 'react';
import { ReferenceField, usePermissions, useNotify, CreateButton, List, Datagrid, AutocompleteInput, ReferenceInput, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { BACKEND_API_KEY, BACKEND_UMS_URL, locationDict, locationTypes } from '../../util/variant'
import { LockField, ValidateField } from '../../control/field/index'
import { CardActions } from '@material-ui/core';
import { hasWritePermissions } from '../../util/function';
import { useState, useEffect } from 'react';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);


const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const ListActions = ({ basePath, data, resource }) => {
    const { permissions } = usePermissions();
    const hasPermissions = hasWritePermissions(permissions);
    if (!hasPermissions) return null;
    return <CardActions style={cardActionStyle}>
        <CreateButton basePath={basePath} />
    </CardActions>
};

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };
var lockeds = [];
lockeds.push({ id: 0, name: 'Non-verrouillé' });
lockeds.push({ id: 1, name: "Verrouillé" });


const ModelFilter = (props) => {
    // const dataProvider = useDataProvider();
    const notify = useNotify();
    // const form = useForm(); // Để truy cập và cập nhật giá trị filter
    const { filterValues, setFilters } = props; // L

    // State để lưu danh sách subcategory
    const [brands, setBrands] = useState([]);
    const [loadingBrands, setLoadingBrands] = useState(false);

    // Danh sách category (có thể lấy từ API hoặc hardcode)
    // const categories = [
    //     { id: 'electronics', name: 'Electronics' },
    //     { id: 'clothing', name: 'Clothing' },
    // ];

    // Lấy giá trị hiện tại của category từ form
    // const { values } = form.getState();
    // const selectedBrandId = values.brand_id;
    const selectedVehicleTypeId = filterValues.vehicle_type_id;


    const loadBrands = async (vehicleTypeId) => {
        if (!vehicleTypeId) {
            setBrands([]);
            // form.change('model_id', null); // Reset subcategory khi không có category
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined }); // Res
            return;
        }

        setLoadingBrands(true);
        try {
            // Gọi API để lấy danh sách subcategory dựa trên category
            const url = BACKEND_UMS_URL + `/vehicle_brand?vehicle_type_id=${vehicleTypeId}&app_key=${BACKEND_API_KEY}`
            console.log("Load brands url: " + url);
            const sessionId = localStorage.getItem("session_id");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            });

            if (!response.ok) {
                throw new Error('Failed to load brands');
            }

            const result = await response.json();
            console.log("Load brands result: " + JSON.stringify(result));
            const { data } = result;
            setBrands(data.map(item => ({ id: item.id, name: item.name })));

            // form.change('model_id', null); // Reset subcategory khi danh sách thay đổi
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined }); // Res
        } catch (error) {
            console.log(error);
            notify('Error loading brands', 'warning');
            setBrands([]);

            // form.change('model_id', null);
            if (typeof setFilters === 'function')
                setFilters({ ...filterValues, brand_id: undefined }); // Res
        } finally {
            setLoadingBrands(false);
        }
    };

    // Gọi loadSubcategories khi selectedCategory thay đổi
    // useEffect(() => {
    //     loadModels(selectedBrandId);
    // }, [selectedBrandId]);
    useEffect(() => {
        loadBrands(selectedVehicleTypeId);
        // loadModels(selectedBrandId);
    }, [selectedVehicleTypeId,]);

    // useEffect(() => {
    //     loadModels(selectedVehicleTypeId, selectedBrandId);
    //     // loadModels(selectedBrandId);
    // }, [selectedVehicleTypeId, selectedBrandId,]);

    return (
        <Filter {...props}>
            {/* <SelectInput
                source="category"
                label="Category"
                choices={categories}
                alwaysOn
                allowEmpty
                emptyText="All Categories"
                
            /> */}
            <TextInput label="Nom" source="name" alwaysOn resettable />
            <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
                reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
                alwaysOn>
                <SelectInput
                    optionText='name' emptyText="Tous"

                />
            </ReferenceInput>
            {/* <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
                sort={{ field: 'name', order: 'ASC' }}
                reference="vehicle_brand" perPage={99999} alwaysOn resettable>
                <AutocompleteInput optionText="name"

                />
            </ReferenceInput> */}
            <SelectInput
                source="brand_id"
                label="Marque"
                choices={brands}
                alwaysOn
                allowEmpty
                emptyText="Toutes les marques"
                disabled={loadingBrands || !selectedVehicleTypeId}
            />
            {/* <SelectInput
                source="model_id"
                label="Modèle"
                choices={models}
                alwaysOn
                allowEmpty
                emptyText="Tous les modèles"
                disabled={loadingModels || !selectedBrandId || !selectedVehicleTypeId}
            /> */}
        </Filter>
    );
};

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn resettable />
        <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_brand" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput> */}
        <SelectInput label="État" source="locked" choices={lockeds}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1' alwaysOn
        />

    </Filter>
);

const ListVehicleModel = ({ permissions, ...props }) => (
    <List title={<Title />} {...props} actions={<ListActions />}
        filters={<ModelFilter />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Nom" />
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand" link="">
                <TextField source="name" />
            </ReferenceField>

            {/* <TextField source="vehicle_brand_name" label="Marque" /> */}
            {/* <TextField source="vehicle_type_name" label="Type de véhicule" /> */}
            {/* <TextField source="seats" label="Sièges"/> */}
            {/* <ImageField source="luggages" label="Icone pour la sélection" size='48'/> */}
            <ValidateField source="locked" label="Verrouillé" />
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListVehicleModel;

