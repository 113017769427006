
import React, { Link } from 'react';
import { usePermissions, Pagination, ReferenceManyField, Datagrid, ReferenceField, SimpleShowLayout, TextField, Show, useTranslate } from 'react-admin';
import { CreateButton, EditButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ValidateField, OrderTypeField } from '../../control/field/index'
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
// import {locationDict} from '../../util/variant'
import { OrderType } from '../../util/constant'
import { hasAdminPermissions } from '../../util/function';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },

});


const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    var string = "";
    if (record) {
        const vehicleTypeName = record ? record.vehicle_type_name : '';
        const orderType = record ? record.order_type : 0;
        if (orderType == OrderType.IMMEDIATE)
            string = vehicleTypeName + " - Immédiate";
        else
            string = vehicleTypeName + " - Réservation à l'avance";
    }
    const translate = useTranslate();
    return <span>{translate('resources.price.show')} pour: {record ? `${string}` : ''}</span>;

};

const ShowPrice = ({ staticContext, ...props }) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const hasPermissions = hasAdminPermissions(permissions);
    if (!hasPermissions) return <span>Aucune permission</span>;

    return (
        <Show title={<Title />} {...props} actions={<ShowActions />}>

            <SimpleShowLayout>

                <TextField source="vehicle_type_name" label="Type de véhicule" />
                <OrderTypeField source="order_type" label="Type de commande" className={classes.inlineBlock} />
                <div />
                <TextField source="base" label="Base(€)" className={classes.inlineBlock} />
                <TextField source="min" label="Minimum(€)" className={classes.inlineBlock} />
                <div />
                <TextField source="per_km" label="€/km" className={classes.inlineBlock} />
                <TextField source="per_minute" label="€/minute" className={classes.inlineBlock} />
                <div />
                <TextField source="wait" label="Attente(€/minute)" className={classes.inlineBlock} />

                {/* <ReferenceManyField reference="price_time" target="price_id" addLabel={true} label="Temps">
                        <Datagrid>
           
                            <ValidateField source="every_day" label="Tous les jours"/>
                            <TextField source="day" label="Dates"/>
                            <TextField source="markup" label="Majorité/Réduction"/>
                            <TextField source="from_time" label="Heure de début"/>
                            <TextField source="to_time" label="Heure de fin"/>
                            <EditButton basePath="/price_time"/>
                        </Datagrid>
                    </ReferenceManyField> */}

            </SimpleShowLayout>
        </Show>
    )
};


export default ShowPrice;