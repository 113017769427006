import React from 'react';
import Typography from '@material-ui/core/Typography';
import { userTranslate } from 'react-admin'
import { OrderStatus } from '../../util/constant';

export var orderRunningStatusValues = [];
orderRunningStatusValues.push({ id: 0, name: "Tous" });
// orderRunningStatusValues.push({id: OrderStatus.CREATED, name: "En attente"});
orderRunningStatusValues.push({ id: OrderStatus.ASSIGNED, name: "En attente" });
orderRunningStatusValues.push({ id: OrderStatus.ACCEPTED, name: "Acceptée par le chauffeur" });
orderRunningStatusValues.push({ id: OrderStatus.TO_PICK, name: "En route pour la prise en charge" });
orderRunningStatusValues.push({ id: OrderStatus.AT_PICK, name: "À la prise en charge" });
orderRunningStatusValues.push({ id: OrderStatus.ONBOARD, name: "Passager à bord" });
orderRunningStatusValues.push({ id: OrderStatus.STARTED, name: "Commencée" });
orderRunningStatusValues.push({ id: OrderStatus.ENDED, name: "Arrivée" });

export var orderFinishedStatusValues = [];
orderFinishedStatusValues.push({ id: 0, name: "Tous" });
orderFinishedStatusValues.push({ id: OrderStatus.DENIED, name: "Aucun chauffeur" });
orderFinishedStatusValues.push({ id: OrderStatus.FINISHED, name: "Complétée" });
orderFinishedStatusValues.push({ id: OrderStatus.CANCELLED, name: "Annulée" });


export const OrderRunningStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    if (status & OrderStatus.ENDED)
        statusText = "Arrivée";
    else
        if (status & OrderStatus.STARTED)
            statusText = "Débutée";
        else
            if (status & OrderStatus.ONBOARD)
                statusText = "Passager à bord";
            else
                if (status & OrderStatus.TO_PICK)
                    statusText = "En route pour la prise en charge";
                else
                    if (status & OrderStatus.AT_PICK)
                        statusText = "À la prise en charge";
                    else
                        if (status & OrderStatus.ACCEPTED)
                            statusText = "Acceptée par le chauffeur";
                        else
                            if (status & OrderStatus.ASSIGNED)
                                statusText = "En attente";
    // else
    //     if(status & OrderStatus.CREATED) 
    //         statusText = "En attente";
    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};

export const OrderFinishedStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    if (status & OrderStatus.CANCELLED)
        statusText = "Annulée";
    else
        if (status & OrderStatus.FINISHED)
            statusText = "Complétée";
        else
            if (status & OrderStatus.DENIED)
                statusText = "Aucun chauffeur";

    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};

const ORDER_CANCEL_BY_CLIENT_BEFORE_ACCEPT = 10;
const ORDER_CANCEL_BY_CLIENT_BEFORE_TO_PICK = 11;
const ORDER_CANCEL_BY_CLIENT_BEFORE_AT_PICK = 12;
const ORDER_CANCEL_BY_CLIENT_BEFORE_ON_BOARD = 13;
const ORDER_CANCEL_BY_CLIENT_BEFORE_START = 14;
const ORDER_CANCEL_BY_CLIENT_BEFORE_END = 15;

const ORDER_CANCEL_BY_DRIVER_BEFORE_TO_PICK = 20;
const ORDER_CANCEL_BY_DRIVER_BEFORE_AT_PICK = 21;
const ORDER_CANCEL_BY_DRIVER_BEFORE_ON_BOARD = 22;
const ORDER_CANCEL_BY_DRIVER_BEFORE_START = 23;
const ORDER_CANCEL_BY_DRIVER_BEFORE_END = 24;
const ORDER_CANCEL_BY_DRIVER_AT_END = 25;

const ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_AVAIL = 30;
const ORDER_CANCEL_BY_SYSTEM_CLIENT_NOT_CHOOSE_DRIVER = 31;
const ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_ACCEPT = 32;
const ORDER_CANCEL_BY_SYSTEM_CLIENT_ABSENT = 33;
const ORDER_CANCEL_BY_SYSTEM_DRIVER_NO_ACTION = 34;
const ORDER_CANCEL_BY_SYSTEM_DRIVER_NOT_TO_PICK = 35;
const ORDER_CANCEL_BY_SYSTEM_ADMIN = 36;

const OrderStatusField = ({ record = {}, label }) => {
    var statusText = 'Inconnu';
    const status = parseInt(record.status);

    if (status & OrderStatus.CANCELLED) {
        var statusText = 'Annulée';
        const cancelType = parseInt(record.cancel_type);
        if (cancelType === ORDER_CANCEL_BY_SYSTEM_ADMIN)
            statusText = "Annulée par le chauffeur";
        else
            if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_ACCEPT)
                statusText = "Annulée par client avant l'acceptation";
            else
                if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_TO_PICK)
                    statusText = "Annulée par client avant la prise en charge";
                else
                    if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_AT_PICK)
                        statusText = "Annulée par client avant la prise en charge";
                    else
                        if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_ON_BOARD)
                            statusText = "Annulée par client avant à bord";
                        else
                            if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_START)
                                statusText = "Annulée par client avant le début";
                            else
                                if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_END)
                                    statusText = "Annulée par client avant l'arrivée";
                                else
                                    if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_TO_PICK)
                                        statusText = "Annulée par le chauffeur avant la prise en charge";
                                    else
                                        if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_AT_PICK)
                                            statusText = "Annulée par le chauffeur avant la prise en charge";
                                        else
                                            if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_ON_BOARD)
                                                statusText = "Annulée par le chauffeur avant à bord";
                                            else
                                                if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_START)
                                                    statusText = "Annulée par le chauffeur avant le début";
                                                else
                                                    if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_END)
                                                        statusText = "Annulée par le chauffeur avant l'arrivée";
                                                    else
                                                        if (cancelType === ORDER_CANCEL_BY_DRIVER_AT_END)
                                                            statusText = "Annulée par le chauffeur à l'arrivée";
                                                        else
                                                            if (cancelType >= ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_AVAIL)
                                                                statusText = "Annulée par le système: Pas de chauffeurs";
    }
    else
        if (status & OrderStatus.FINISHED)
            statusText = "Complétée";
        else
            if (status & OrderStatus.ENDED)
                statusText = "Arrivée";
            else
                if (status & OrderStatus.STARTED)
                    statusText = "Débutée";
                else
                    if (status & OrderStatus.ONBOARD)
                        statusText = "Passager à bord";
                    else
                        if (status & OrderStatus.TO_PICK)
                            statusText = "En route pour la prise en charge";
                        else
                            if (status & OrderStatus.AT_PICK)
                                statusText = "À la prise en charge";
                            else
                                if (status & OrderStatus.ACCEPTED)
                                    statusText = "Acceptée par le chauffeur";
                                else
                                    if (status & OrderStatus.DENIED)
                                        statusText = "Aucun chauffeur";
                                    else
                                        if (status & OrderStatus.ASSIGNED)
                                            statusText = "Attribuée au chauffeur";
                                        else
                                            if (status & OrderStatus.CREATED)
                                                statusText = "En attente";
    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
}

OrderStatusField.defaultProps = {
    addLabel: true,
};

export default OrderStatusField;
