import React, { Fragment } from 'react';
import { CardActions, CreateButton, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';

import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { ValidateField, FormattedDateField } from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);

const ListActions = ({ basePath }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    { id: 0, name: 'Non-vérrouillé' },
    { id: 1, name: 'Vérrouillé' }
];

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Numéro de véhicule" source="plate_number" alwaysOn resettable />
        <SelectInput label="État" source="is_locked" alwaysOn choices={statusList}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'
        />

    </Filter>
);

const ListVehicle = ({ permissions, ...props }) => (
    <React.Fragment>
        <List title={<Title />} {...props} actions={<ListActions />}
            bulkActionButtons={<BulkActionButtons />}
            filters={<ListFilter permissions={permissions} />}
            exporter={false}>
            <Datagrid rowClick="show">
                <TextField source="plate_number" label="Numéro de véhicule" />
                <FormattedDateField source="circulation_date" label="Date de première mise en circulation" locales="fr-FR" />
                <ValidateField source="locked" label="Verrouillée" />
                {/* <EditButton/> */}
            </Datagrid>
        </List>
        {/* <Route
           path="/vehicle_brand/create"
           render={() => (
               <CreateVehicleBrand {...props} />
           )} */}

    </React.Fragment>
);

export default ListVehicle;

