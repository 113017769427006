import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import VehicleIcon from '@material-ui/icons/DriveEta';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { translate } from 'react-admin';

import CardIcon from '../../control/view/card-icon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const DriverOnlineNbDashboard = ({ value, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={VehicleIcon} bgColor="#0f0" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
            <Link href="#">
            {translate('dashboard.drivers.online')}
                </Link>
                
                
            </Typography>
            <Typography variant="headline" component="h2">
                {value}
            </Typography>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(DriverOnlineNbDashboard);
