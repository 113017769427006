import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    NumberInput,
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    crudUpdate,
    UPDATE,
    BooleanInput,
    FileField,
    REDUX_FORM_NAME
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import ActionSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import RichTextInput from 'ra-input-rich-text';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DateInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

import jsonDataProvider from '../../provider/json-data-provider';
import {httpClient} from '../../util/function'
import {BACKEND_UMS_URL} from '../../util/variant'
import {DataStatus} from '../../util/constant'

import { log } from 'util';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);


class WalletCreditButton extends Component {
    state = {
        error: false,
        showDialog: false,
        showError: false,
    };

    handleClick = () => {
        const { record } = this.props;
        if(record.wallet.balance === 0){
            this.setState({ showDialog: true, showError: false });
        }
        else{
            this.setState({ showDialog: false, showError: true });
        }
        
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleCloseErrorClick = () => {
        this.setState({ showError: false });
    };

    handleSaveClick = () => {
        console.log('Edit doc validity date button props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { record, crudUpdate, basePath } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DocStatus.REJECTED;

        console.log('Edit wallet button values: ' + JSON.stringify(values)); 
        // crudUpdate('wallet', record.wallet.id, {balance: values['wallet']['balance']}, basePath, 'show', true);
        crudUpdate('wallet', record.wallet.id, {credit: 1}, basePath, 'show', true);

        // , reason: values['reason']
        // dataProvider(UPDATE, 'doc_date', { id: record.date_id, data: {id: record.date_id, date: values['date']}})
        //     .then(({ data }) => {
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        this.setState({ showDialog: false });
    };

   

    render() {
        const { showDialog, showError } = this.state;
        const { isSubmitting, record } = this.props;

        console.log('Record vehicle: ' + JSON.stringify(record));
       
        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let submit;
        return (
            <Fragment>
                {/* {record.wallet.balance === 0 && */}
                <Button onClick={this.handleClick} label="Créditer">
                    <EditIcon color={record.wallet.balance === 0 ? 'primary': '#ffaaa'}/>
                </Button>
                {/* } */}
                {/* {record.wallet.balance > 0 && <span>Le bouton Créditer est disponible seulement quand le wallet a été débité</span>} */}
                <Dialog
                    maxWidth="800xs"
                
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Créditer le wallet"
                >
                    <DialogTitle>Créditer le wallet</DialogTitle>
                    <DialogContent>
                    <Form
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="edit-driver-settings-form" onSubmit={handleSubmit}>
                                <div>
                                {/* <NumberInput source="wallet.balance" label="Montant crédité (Franc CFA)"/> */}
                                Voulez-vous créditer le wallet du chauffeur ?

                                    {/* <span>Current doc date: {record.date}</span> */}
                                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <DateInput source="circulation_date" label="" defaultValue={record.circulation_date}
                                            options={{ format: 'DD/MM/YYYY' }} 
                                                providerOptions={{ utils: DateFnsUtils, locale: frLocale }} fullWidth
                                            />
                                           
                                    </MuiPickersUtilsProvider> */}
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >

                    </Form>
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                        <Button label="ra.action.confirm" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('edit-driver-settings-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <ActionSave />
                        </Button>
                        {/* <button
                            type="submit"
                            onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('exampleForm')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            }
                            >
                            Save
                            </button> */}
                    </DialogActions>
                </Dialog>


                <Dialog
                    maxWidth="800xs"
                
                    open={showError}
                    onClose={this.handleCloseErrorClick}
                    aria-label="Créditer le wallet"
                >
                    <DialogTitle>Attention !</DialogTitle>
                    <DialogContent>
                    <Form
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="edit-driver-settings-form" onSubmit={handleSubmit}>
                                <div>
                                {/* <NumberInput source="wallet.balance" label="Montant crédité (Franc CFA)"/> */}
                                Vous pouvez créditer le wallet seulement quand il a déjà été débité

                                    {/* <span>Current doc date: {record.date}</span> */}
                                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <DateInput source="circulation_date" label="" defaultValue={record.circulation_date}
                                            options={{ format: 'DD/MM/YYYY' }} 
                                                providerOptions={{ utils: DateFnsUtils, locale: frLocale }} fullWidth
                                            />
                                           
                                    </MuiPickersUtilsProvider> */}
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >

                    </Form>
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        <Button label="OK" onClick={this.handleCloseErrorClick}>
                            <IconCancel />
                        </Button>
                        {/* <Button label="ra.action.confirm" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('edit-driver-settings-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <ActionSave />
                        </Button> */}
                        {/* <button
                            type="submit"
                            onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('exampleForm')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            }
                            >
                            Save
                            </button> */}
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting()(state)
});

WalletCreditButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

// export default connect(mapStateToProps, mapDispatchToProps)(
//     DocDateEditButton
// );
export default connect(undefined, { crudUpdate })(WalletCreditButton);

    // handleClick = () => {
    //     // const { push, record, showNotification } = this.props;
    //     // const updatedRecord = { ...record, is_approved: true };
    //     // fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
    //     //     .then(() => {
    //     //         showNotification('Comment approved');
    //     //         push('/comments');
    //     //     })
    //     //     .catch((e) => {
    //     //         showNotification('Error: comment not approved', 'warning')
    //     //     });
    // }

    // render() {
    //     return (
    //             <Button label={this.props.label} onClick={this.handleClick}>
    //                 <ActionEmail/>
    //             </Button>
            
    //     );
    // }
// }

// SendMailButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(SendMailButton);