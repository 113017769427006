import React from 'react';
import {
    minValue, ArrayInput, SimpleFormIterator, RadioButtonGroupInput, Edit, BooleanInput, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm,
    FormTab, useTranslate
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/config"
            submitOnEnter={true}
        />

    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.settings')}</span>;
};

const validateNotSmallerThanOne = (value, allValues) => {
    if (value < 1) {
        return 'Doit ne pas être moins que 1';
    }
    return [];
};

const validateNoGreaterThan60 = (value, allValues) => {
    if (value > 60) {
        return 'Doit ne pas être plus que 60';
    }
    return [];
};

const validateNotSmallerThan30 = (value, allValues) => {
    if (value < 30) {
        return 'Doit ne pas être moins que 30';
    }
    return [];
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être >= 0';
    }
    return [];
};

// const validateGreaterThanZero = (value, allValues) => {
//     if (value < 1) {
//         return 'Doit être au moins 1';
//     }
//     return [];
// };

const validateTimeoutAccept = [required(), number(), minValue(30), validatePositive];
const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThanOneNumber = [required(), number(), validateNotSmallerThanOne];
const notSmallerThan30 = [required(), number(), validateNotSmallerThan30];

const EditConfig = ({ classes, permissions, ...props }) => {
    // const EditConfig = (permissions, ...props) => {
    // if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
        <Edit
            actions={<EditActions />}
            id='noid'
            resource="config"
            basePath="/config"
            redirect="false"
            title={<Title />}
            {...props}
        >

            {/* <SimpleForm redirect="list">
                
            <TextInput source="currency_code" label="Code de monnaie"/>
            

        </SimpleForm> */}
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label="Générale">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                    {/* <TextInput source="unit_time" label="Symbole de monnaie"/> */}
                    <NumberInput source="commission" label="Commission service (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat" label="TVA(%)" validate={validateNotNegativeNumber} />
                    {/* <BooleanInput source="available" label="Service disponible" /> */}
                    <TextInput source="cities" label="Villes pour la disponibilité" fullWidth />
                    {/* <TextInput source="cities_error" label="Message pour valider la ville des commandes" fullWidth multiline='true' /> */}

                    {/* <NumberInput source="vat_2" label="TVA Personne physique(%)" validate={validateNotNegativeNumber}/>
                <NumberInput source="vat_6" label="TVA Société(%)" validate={validateNotNegativeNumber}/> */}
                    {/* <NumberInput source="doc_validation_warning_before" label="Informé pour l'expiration de documents avant (jours)"  
                initialValue={1} style={{'width':'600px'}}
                validate={validateNotSmallerThanOneNumber}/> */}
                </FormTab>
                <FormTab label="Commande">

                    {/* <TextInput fullWidth source="manual_dispatch_postal_codes" style={{'width':'50%'}} helperText="Exemple: 13001-13999, 14000, 14007. Tandis que 13001-13999 pour les codes postaux de 13001 à 13000. 14000 et 14007 sont des valuers individuelles. Séparées par des virgules"
                    label="Zones (codes postaux) pour le dispatching manuel"/> */}
                    {/* <NumberInput source="timeout_order_wait_for_accept" label="Délai d'acceptation (secondes)" validate={notSmallerThan30} style={{ 'width': '600px' }} /> */}
                    <NumberInput source="pick_up_radius" label="Rayon autour point de départ(km) pour recevoir la commande" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="at_pick_radius" label="Rayon autour point de départ(m) pour déclarer sur place" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="free_wait_min" label="Temps d'attente (minutes)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="cancel_max" label="Nombre d'annulation du client max avant d'être bloqué" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="cancel_period" label="Laps de temps d'annulation du client avant d'être bloqué (heures)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="cancel_block_period" label="Durée de blocage (heures)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />


                    {/* <NumberInput source="order_free_wait_min" label="Temps d'attente gratuit (minutes)" validate={validateNoGreaterThan60} style={{ 'width': '600px' }} />
                    <NumberInput source="order_paid_wait_min" label="Temps d'attente payant max. (minutes)" validate={validateNoGreaterThan60} style={{ 'width': '600px' }} />
                    <NumberInput source="wait_price" label="Prix d'attente (€/minute)" initialValue={0} validate={required()} style={{ 'width': '600px' }} /> */}
                    {/* <NumberInput source="cancel_free_driver" label="Temps d'annulation gratuit(s) du chauffeur" validate={required()} style={{'width':'600px'}}/> */}


                    {/* <NumberInput source="service_avail_center_lat" label="Latitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_center_lng" label="Longitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_radius" label="Rayon du service(km)" validate={validateNotNegativeNumber} style={{'width':'25%'}}/> */}


                    {/* <Typography variant="h6" gutterBottom>Immédiate</Typography> */}

                    {/* <NumberInput source="wait_radius" label="Rayon autour point de départ(m) pour l'attente" validate={required()} style={{'width':'600px'}}/>
                <NumberInput source="dispose_radius" label="Rayon autour point de dépose(m) pour l'alerte" validate={required()} style={{'width':'600px'}}/> */}

                    {/* <NumberInput source="free_cancel_min_immediate" label="Temps d'annulation gratuit(minutes) après l'acceptation et avant sur place - Immédiate" validate={required()} style={{ 'width': '800px' }} />
                    <NumberInput source="cancel_fee_immediate" label="Frais d'annulation (€) après le temps d'annulation gratuite et avant sur place - Immédiate" validate={required()} style={{ 'width': '800px' }} />

                    <NumberInput source="free_cancel_time_reservation" label="Temps d'annulation gratuit(minutes) avant le prise en charge - Réservation" validate={required()} style={{ 'width': '800px' }} />
                    <NumberInput source="cancel_fee_reservation" label="Frais d'annulation (€) avant la prise en charge - Réservation" validate={required()} style={{ 'width': '800px' }} />

                    <NumberInput source="cancel_fee_waiting" label="Frais d'annulation (€) pendant l'attente" validate={required()} style={{ 'width': '800px' }} /> */}
                    {/* <RadioButtonGroupInput source="cancel_amount_before_pick_time_unit" label="Unité du montant d'annulation" 
                choices={[
                    { id: '0', name: '€' },
                    { id: '1', name: '%' },
                ]} initialValue='0'/> */}

                    {/* <Typography variant="h6" gutterBottom>Réservation à l'avance</Typography> */}
                    {/* <NumberInput source="soonest_reservation_time" label="Heure de réservation au plus tôt (minutes)" validate={validateNotNegativeNumber} style={{'width':'600px'}}/>
                <NumberInput source="latest_reservation_time" label="Heure de réservation au plus tard (minutes)" validate={validateNotNegativeNumber} style={{'width':'600px'}}/> */}
                </FormTab>

                {/* <FormTab label="Code de réduction">
                <TextInput source="first_order_promo_code_value" label="Valeur du code de réduction pour le nouveau compte client" fullWidth validate={required()}/>
                
            </FormTab> */}
                {/* <FormTab label="Comptes">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab> */}


                <FormTab label="Emails">
                    {/* <TextInput source="company_name" label="Nom" validate={required()} fullWidth />
                    <TextInput source="company_address_line1" label="Adresse ligne 1" validate={required()} fullWidth multiline />
                    <TextInput source="company_address_line2" label="Adresse ligne 2" validate={required()} fullWidth multiline />
                    <TextInput source="company_email" label="Email" />
                    <TextInput source="company_phone" label="Téléphone" /> */}
                    <TextInput source="contact_email" label="Email de contact" />
                    {/* <TextInput source="register_email" label="Email pour l'inscription" /> */}
                    <TextInput source="sender_email" label="Email d'expéditeur" />
                    {/* <TextInput source="urgent_phone" label="Téléphone pour l'urgence" /> */}
                </FormTab>


            </TabbedForm>


        </Edit>
    );
};

export default EditConfig;
